import { createContext, useContext, useState } from 'react';
import { Segment } from '@fieldmargin/webapp-reporting';
import FieldNameOnboarding from 'fields/onboarding/FieldNameOnboarding';
import FieldOnboarding from 'fields/onboarding/FieldOnboarding';
import FieldUsageOnboarding from 'fields/onboarding/FieldUsageOnboarding';
import InputOnboarding from 'inputs/onboarding/InputOnboarding';
import OperationOnboarding from 'operations/onboarding/OperationOnboarding';

import OnboardingEnd from './OnboardingEnd';
import OnboardingFarmType from './OnboardingFarmType';
import OnboardingStart from './OnboardingStart';

/**
 * This state is not intended to be used with Redux but instead to be used as context
 * within React.
 */
export const useOnboardingState = (): [
    string | undefined,
    VoidFunction,
    (nextStep?: string) => void,
    (startStep?: string) => void,
] => {
    const [step, setStep] = useState<string>();
    const [running, setRunning] = useState(false);

    const skip = () => {
        Segment.track('Onboarding skipped', { step });
        setRunning(false);
        setStep(undefined);
    };
    const next = (nextStep?: string) => {
        if (running) {
            setStep(nextStep);
        }
    };
    const start = (startStep?: string) => {
        setRunning(true);
        setStep(startStep || 'farmType');
    };

    return [step, skip, next, start];
};

interface OnboardingContextState {
    activeStep: string | undefined;
    skipOnboarding: () => void;
    nextOnboarding: (nextStep?: string) => void;
    startOnboarding: (nextStep?: string) => void;
}

export const OnboardingContext = createContext({} as OnboardingContextState);

export const onboardingSteps = {
    farmType: OnboardingFarmType,
    start: OnboardingStart,
    fields: FieldOnboarding,
    fieldName: FieldNameOnboarding,
    fieldUsage: FieldUsageOnboarding,
    fieldJob: OperationOnboarding,
    inputs: InputOnboarding,
    end: OnboardingEnd,
};

export interface OnboardingProps {
    activeStep: string | null;
    skipOnboarding: VoidFunction;
    nextOnboarding: (nextStep?: string) => void;
    startOnboarding: (nextStep?: string) => void;
}

/**
 * A higher order component to be used to inject the onboarding props into
 * the given component. Most likely to be used with a Class component.
 */
export const withOnboarding =
    <P extends object>(Component: React.ComponentType<P>) =>
    (props: P) => {
        const { activeStep, skipOnboarding, nextOnboarding, startOnboarding } = useOnboarding();
        return (
            <Component
                {...props}
                activeStep={activeStep}
                skipOnboarding={skipOnboarding}
                nextOnboarding={nextOnboarding}
                startOnboarding={startOnboarding}
            />
        );
    };

/**
 * Hook that can be used to get the onboarding state.
 * Should be used with a functional component.
 */
export const useOnboarding = () => useContext(OnboardingContext);

export const getOnboardingClassName = (wanted: string, active?: string) =>
    active === wanted ? 'forefront' : '';
