import type { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useToggle } from '@fieldmargin/webapp-state';
import Checkbox from '@fieldmargin/webapp-styling/components/checkbox';
import { clsx } from 'clsx';
import { LazyDoughnutChart } from 'components/chart/LazyDoughnutChart';
import { selectYearTotalFieldArea } from 'fields/fields-selectors';
import type FieldUsage from 'fields/FieldUsage';
import { asBadge } from 'fields/FieldUsage';
import { roundToDp } from 'lib/util/number';
import { useAppSelector } from 'system/store';
import BadgeItem from 'view/molecules/badge-item/BadgeItem';
import FormattedArea from 'view/units/FormattedArea';
import ShortAreaUnit from 'view/units/ShortAreaUnit';
import { selectCurrentYear } from 'years/years-state';

import { usageDataToChartData } from './usage-reporting-maths';
import { selectUsageReportData } from './usage-reporting-selectors';

export interface UsageReportDatum {
    fieldUsage: FieldUsage;
    areaSqm: number;
    percentage: number;
}

const UsageReporting = () => {
    const { t, i18n } = useTranslation();
    const [includeArchived, toggleIncludeArchived] = useToggle(false);

    const year = useAppSelector(selectCurrentYear);
    const totalArea = useAppSelector(selectYearTotalFieldArea);
    const data = useAppSelector((state) => selectUsageReportData(state, includeArchived));

    if (data.length === 0) {
        return null;
    }

    return (
        <div className="rounded bordered p-5 text-base">
            <h2 className="uppercase text-xl">{t('usage_report_title', { sprintf: [year] })}</h2>

            <div>
                <LazyDoughnutChart
                    style={{ display: 'block', maxHeight: 450 }}
                    data={usageDataToChartData(data)}
                    options={{
                        locale: i18n.language,
                        cutout: 25,
                        layout: {
                            padding: {
                                top: 15,
                                bottom: 10,
                                left: 20,
                                right: 20,
                            },
                        },
                        plugins: {
                            legend: {
                                display: false,
                            },
                            datalabels: {
                                anchor: 'end',
                                backgroundColor: 'white',
                                borderColor: (context) => {
                                    return context.dataset.backgroundColor
                                        ? context.dataset.backgroundColor[context.dataIndex]
                                        : 'black';
                                },
                                borderWidth: 1,
                                borderRadius: 25,
                                color: 'black',
                                font: {
                                    weight: 'bold',
                                },
                                formatter: (value) => `${value}%`,
                            },
                        },
                    }}
                />
                <div className="mt-8">
                    <Checkbox
                        id="includeArchivedFields"
                        label={t('include_archived_fields')}
                        checked={includeArchived}
                        onChange={toggleIncludeArchived}
                    />
                </div>
                <table className="table-fixed border-collapse w-full mt-4">
                    <thead>
                        <tr className="bg-gray-100">
                            <TableHeader className="w-1/2">{t('usage')}</TableHeader>
                            <TableHeader className="w-1/3">
                                {t('area')} (<ShortAreaUnit />)
                            </TableHeader>
                            <TableHeader className="w-1/6">%</TableHeader>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((datum) => (
                            <tr key={datum.fieldUsage.uuid}>
                                <TableCell>
                                    <BadgeItem item={asBadge(datum.fieldUsage)} truncate={false} />
                                </TableCell>
                                <TableCell>
                                    <FormattedArea areaSqm={datum.areaSqm} withUnit />
                                </TableCell>
                                <TableCell>{roundToDp(datum.percentage, 0)}</TableCell>
                            </tr>
                        ))}
                        <tr>
                            <TableCell>
                                <strong>{t('field_work_area_total')}</strong>
                            </TableCell>
                            <TableCell>
                                <strong>
                                    <FormattedArea areaSqm={totalArea} withUnit />
                                </strong>
                            </TableCell>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
};

const TableHeader = ({ children, className }: { children: ReactNode; className?: string }) => (
    <th className={clsx('text-left py-2 px-3 bordered', className)}>{children}</th>
);

const TableCell = ({ children }: { children: ReactNode }) => (
    <td className="py-2 px-3 bordered">{children}</td>
);

export default UsageReporting;
