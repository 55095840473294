import type { Farm } from '@fieldmargin/webapp-farms';
import { useSegmentMountTracking } from '@fieldmargin/webapp-reporting';
import type Field from 'fields/Field';
import type FieldUsage from 'fields/FieldUsage';
import { useTranslations } from 'hooks/useTranslations';
import type { List } from 'immutable';
import { joinNicely } from 'lib/util/text';
import { prop } from 'ramda';

import PesticideCheckEditUsage from './PesticideCheckEditUsage';

interface PesticideCheckUsagesProps {
    farm: Farm;
    onBack: VoidFunction;
    onNext: VoidFunction;
    invalidFields: List<Field>;
    invalidFieldUsages: List<FieldUsage>;
}

const PesticideCheckUsages = ({
    farm,
    onBack,
    onNext,
    invalidFields,
    invalidFieldUsages,
}: PesticideCheckUsagesProps) => {
    const { plural } = useTranslations();
    useSegmentMountTracking('Pesticide check set field usages seen', {
        farmUuid: farm.uuid,
    });
    return (
        <>
            <p>
                {joinNicely(invalidFields.map(prop('name')))}{' '}
                {plural('pesticide_check_usage_invalid', { count: invalidFields.size })}
            </p>
            <PesticideCheckEditUsage
                onDone={onNext}
                onBack={onBack}
                invalidUsages={invalidFieldUsages}
            />
        </>
    );
};

export default PesticideCheckUsages;
