import type { MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Segment } from '@fieldmargin/webapp-reporting';
import { useToggle } from '@fieldmargin/webapp-state';
import { selectCurrentFarm } from 'farms/farms-state';
import type { List } from 'immutable';
import type Output from 'outputs/Output';
import { selectActiveOutputsSortedByName } from 'outputs/outputs-state';
import PlusButton from 'sidebar/modules/PlusButton';
import type { SingleParamVoidFunction } from 'system/types';
import ShortUnit from 'view/units/ShortUnit';

interface OutputSelectorProps {
    selectedOutputUuid?: string;
    onSelectOutput: SingleParamVoidFunction<Output>;
    onAddOutput: VoidFunction;
}

const OutputSelector = ({
    selectedOutputUuid,
    onSelectOutput,
    onAddOutput,
}: OutputSelectorProps) => {
    const { t } = useTranslation();
    const farm = useSelector(selectCurrentFarm);
    const sortedOutputs = useSelector(selectActiveOutputsSortedByName);
    const [open, toggleOpen] = useToggle(false);

    const selectedOutput = sortedOutputs.find((output) => output.uuid === selectedOutputUuid);

    const handleSelectOutput = (output: Output) => {
        Segment.track('Add output', { farmUuid: farm.uuid, outputUuid: output.uuid });
        onSelectOutput(output);
        toggleOpen();
    };

    const handleToggleOpen = (e?: MouseEvent) => {
        e && e.preventDefault();
        toggleOpen();
    };

    return (
        <div className="relative w-1/3">
            <button
                className="bare-btn bg-white bordered p-2 rounded-sm w-full h-10 flex justify-between cursor-pointer focus"
                onClick={handleToggleOpen}
            >
                <span className="truncate pr-1">
                    {selectedOutput !== undefined ? (
                        <>
                            {selectedOutput.name} (<ShortUnit unit={selectedOutput.unit} />)
                        </>
                    ) : (
                        <em className="text-gray-400">{t('none_selected')}</em>
                    )}
                </span>
                <i className="ion-chevron-down ml-1" />
            </button>
            {open && (
                <OutputSelectorList
                    outputs={sortedOutputs}
                    onSelectOutput={handleSelectOutput}
                    onAddOutput={onAddOutput}
                />
            )}
        </div>
    );
};

export default OutputSelector;

interface OutputSelectorListProps {
    outputs: List<Output>;
    onSelectOutput: SingleParamVoidFunction<Output>;
    onAddOutput: VoidFunction;
}
const OutputSelectorList = ({ outputs, onSelectOutput, onAddOutput }: OutputSelectorListProps) => {
    const { t } = useTranslation();
    const handleSelect = (output: Output) => (e?: MouseEvent) => {
        e && e.preventDefault();
        onSelectOutput(output);
    };
    return (
        <ol className="list-none p-0 overflow-auto max-h-80 absolute z-20 top-full w-full rounded-sm bg-white shadow">
            {outputs.map((output) => (
                <li className="bordered-t relative p-0" key={output.uuid}>
                    <button
                        className="bare-btn w-full p-2 text-left cursor-pointer focus ring-inset"
                        onClick={handleSelect(output)}
                    >
                        {output.name} (<ShortUnit unit={output.unit} />)
                    </button>
                </li>
            ))}
            <li className="bordered-t relative py-2">
                <PlusButton className="w-full" onClick={onAddOutput}>
                    {t('add_output')}
                </PlusButton>
            </li>
        </ol>
    );
};
