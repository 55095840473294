import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import type { Farm } from '@fieldmargin/webapp-farms';
import Button from '@fieldmargin/webapp-styling/components/button';
import { selectCurrentFarm } from 'farms/farms-state';
import type { List, Map } from 'immutable';
import type FarmIntegration from 'integrations/FarmIntegration';
import { bindActionCreators } from 'redux';
import type FarmIntegrationSensors from 'sensors/FarmIntegrationSensors';
import EmptyState from 'sidebar/modules/EmptyState';
import SidebarModule from 'sidebar/modules/SidebarModule';
import type { AppState } from 'system/store';
import { changeModal } from 'system/ui-state';

import { fetchIntegrationSensors } from '../../sensors-state';

import SensorListIntegrationGroup from './SensorListIntegrationGroup';
import SensorsListGroupHeader from './SensorsListGroupHeader';

interface IntegrationSensorsListProps {
    farm: Farm;
    sensorsByIntegration: Map<string, FarmIntegrationSensors>;
    farmIntegrations: List<FarmIntegration>;
    fetchIntegrationSensors: typeof fetchIntegrationSensors;
    fetchIntegrationSensorsPending: boolean;
    fetchIntegrationSensorsError: boolean;
    changeModal: typeof changeModal;
}

const IntegrationSensorsList = ({
    farm,
    sensorsByIntegration,
    farmIntegrations,
    fetchIntegrationSensors,
    fetchIntegrationSensorsPending,
    fetchIntegrationSensorsError,
    changeModal,
}: IntegrationSensorsListProps) => {
    const { t } = useTranslation();
    useEffect(() => {
        fetchIntegrationSensors(farm.uuid);
    }, []);

    if (fetchIntegrationSensorsError) {
        return (
            <>
                <SensorsListGroupHeader
                    name={t('sensors_connected_title')}
                    active={false}
                    updating={false}
                />
                <SidebarModule editing>{t('sensors_loading_error')}</SidebarModule>
            </>
        );
    }

    if (!sensorsByIntegration) {
        return (
            <>
                <SensorsListGroupHeader
                    name={t('sensors_connected_title')}
                    active={false}
                    updating={true}
                />
                <SidebarModule editing>{t('sensors_loading')}</SidebarModule>
            </>
        );
    }

    if (sensorsByIntegration.size === 0) {
        return (
            <>
                <EmptyState header={t('sensors_connected_empty_title')}>
                    <Button
                        key="more-info"
                        onClick={() => {
                            changeModal({
                                name: 'connections',
                                props: {
                                    initialSection: 'sensors',
                                },
                            });
                        }}
                    >
                        {t('label_find_out_more')}
                    </Button>
                </EmptyState>
            </>
        );
    }

    return (
        <>
            {sensorsByIntegration
                .map((farmIntegrationSensors) => {
                    let matchingIntegration: FarmIntegration | undefined = undefined;
                    if (farmIntegrations) {
                        matchingIntegration = farmIntegrations.find(
                            (fi) => fi.uuid === farmIntegrationSensors.farmIntegrationUuid
                        );
                    }

                    if (!matchingIntegration) {
                        return null;
                    }

                    return (
                        <SensorListIntegrationGroup
                            key={farmIntegrationSensors.farmIntegrationUuid}
                            farmIntegrationSensors={farmIntegrationSensors}
                            farmIntegration={matchingIntegration}
                            fetchIntegrationSensorsPending={fetchIntegrationSensorsPending}
                            changeModal={changeModal}
                        />
                    );
                })
                .toList()}
        </>
    );
};

export default connect(
    (state: AppState) => ({
        farm: selectCurrentFarm(state),
        sensorsByIntegration: state.sensorsState.sensorsByIntegration,
        farmIntegrations: state.integrationsState.farmIntegrations,
        fetchIntegrationSensorsPending: state.sensorsState.fetchIntegrationSensorsPending,
        fetchIntegrationSensorsError: state.sensorsState.fetchIntegrationSensorsError,
    }),
    (dispatch) =>
        bindActionCreators(
            {
                fetchIntegrationSensors,
                changeModal,
            },
            dispatch
        )
)(IntegrationSensorsList);
