import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Navigate } from 'react-router-dom';
import type { Farm, FarmUser } from '@fieldmargin/webapp-farms';
import Button from '@fieldmargin/webapp-styling/components/button';
import icon from 'components/icons/icon';
import { selectCurrentFarm } from 'farms/farms-state';
import { useCurrentOperation } from 'hooks/useCurrentOperation';
import { useDateFormat } from 'hooks/useDateFormat';
import type { List } from 'immutable';
import { Set } from 'immutable';
import type { MeasurementUnit } from 'lib/MeasurementUnit';
import { printOut } from 'lib/print';
import { getDisplayName } from 'lib/util/names';
import type { FullOperationField } from 'operations/OperationField';
import { selectFullOperationFields } from 'operations/operations-selectors';
import { isHarvestOperation } from 'operations/sidebar/new/operation-new-utils';
import type { AppState } from 'system/store';
import { useAppSelector } from 'system/store';
import { selectFarmUsersFromSet } from 'team/farm-users-state';
import UpgradeButton from 'upgrade/UpgradeButton';
import { selectUserAreaMeasurementUnit } from 'users/user-state';
import fmLogo from 'view/system/logo/fm-logo.svg';

import { getOperationTypeName } from '../Operation';

import OperationPrintSheetRecordingTable from './OperationPrintSheetRecordingTable';
import OperationPrintSheetYieldTable from './OperationPrintSheetYieldTable';

interface OperationPrintSheetProps {
    farm: Farm;
    operationFields: List<FullOperationField>;
    measurementUnit: MeasurementUnit;
    farmUsers: FarmUser[];
}

const OperationPrintSheet = ({
    farm,
    operationFields,
    measurementUnit,
    farmUsers,
}: OperationPrintSheetProps) => {
    const { currentOperation } = useCurrentOperation();
    const { format } = useDateFormat();

    const taggedUsers = useAppSelector((state: AppState) =>
        currentOperation !== undefined
            ? Set(selectFarmUsersFromSet(state, currentOperation.summary.taggedUserIds))
            : Set()
    );

    if (currentOperation === undefined) {
        return <Navigate to={`/farms/${farm.uuid}/notes`} />;
    }

    if (!farm.plan.domainPrinting) {
        return (
            <div className="bg-white h-content-full text-black p-8">
                <Link
                    className="flex items-center font-bold mb-6"
                    to={`/farms/${farm.uuid}/operations/${
                        currentOperation ? currentOperation.uuid : ''
                    }`}
                >
                    {icon('back', 'dark-blue', 'mr-2')} Back
                </Link>
                <p>Job sheet printing is not available on the {farm.plan.displayName} plan.</p>
                <p>
                    <UpgradeButton />
                </p>
            </div>
        );
    }

    const isHarvest = isHarvestOperation(currentOperation.summary.type);

    return currentOperation ? (
        <div className="bg-white h-content-full print:h-auto overflow-auto p-8 text-black">
            <div className="no-print flex items-center mb-6">
                <Link
                    className="flex items-center font-bold mr-8"
                    to={`/farms/${farm.uuid}/operations/${currentOperation.uuid}`}
                >
                    {icon('back', 'dark-blue', 'mr-2')} Back
                </Link>
                <Button onClick={() => printOut()} className="flex items-center">
                    {icon('print', 'white', 'mr-3')} Print
                </Button>
            </div>
            <img
                src={fmLogo}
                alt="fieldmargin logo"
                className="h-8 hidden print:block print:mb-4"
            />
            <div className="flex mb-8">
                <div className="w-1/2">
                    <h1 className="text-sm mb-0">{farm.name}</h1>
                    <p className="mb-0">{currentOperation.summary.name}</p>
                    <p className="mb-0">
                        Job type: {getOperationTypeName(currentOperation.summary.type)}
                    </p>
                    {currentOperation.summary.dueDate && (
                        <p className="mb-0">
                            Due: {format(currentOperation.summary.dueDate, 'do MMMM yyyy')}
                        </p>
                    )}
                    {farmUsers && taggedUsers.size > 0 && (
                        <p className="mb-0">
                            Tagged people:{' '}
                            {taggedUsers
                                .map((farmUser) => getDisplayName(farmUsers, farmUser.id))
                                .join(', ')}
                        </p>
                    )}
                </div>
                <div className="w-1/2">
                    <p className="mb-0">
                        Completed:{' '}
                        {currentOperation.summary.completedDate &&
                            format(currentOperation.summary.completedDate, 'do MMMM yyyy, HH:mm')}
                    </p>
                </div>
            </div>
            {!isHarvest && currentOperation.recordings && operationFields.size > 0 && (
                <OperationPrintSheetRecordingTable
                    operationFields={operationFields}
                    recordings={currentOperation.recordings}
                    measurementUnit={measurementUnit}
                />
            )}
            {isHarvest && currentOperation.summary.outputUuid && operationFields.size > 0 && (
                <OperationPrintSheetYieldTable
                    outputUuid={currentOperation.summary.outputUuid}
                    operationFields={operationFields}
                    measurementUnit={measurementUnit}
                />
            )}
            <p className="hidden print:block w-full text-center fixed bottom-0">
                Created using fieldmargin. For more information visit www.fieldmargin.com
            </p>
        </div>
    ) : null;
};

export default connect((state: AppState) => ({
    farm: selectCurrentFarm(state),
    operationFields: selectFullOperationFields(state, useCurrentOperation().currentOperation),
    measurementUnit: selectUserAreaMeasurementUnit(state),
    farmUsers: state.farmUsersState.farmUsers,
}))(OperationPrintSheet);
