import type { Reducer } from 'react';
import { useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { Segment } from '@fieldmargin/webapp-reporting';
import { usePromise } from '@fieldmargin/webapp-state';
import { setMapView } from 'farm-editing/farm-editing-state';
import type Field from 'fields/Field';
import { setFields } from 'fields/fields-state';
import { usePageParams } from 'hooks/usePageParamas';
import { List } from 'immutable';
import { useAppDispatch } from 'system/store';
import TakeOverModal from 'view/modal/TakeOverModal';

import ImportRpaFields from './ImportRpaFields';
import ImportRpaParcels from './ImportRpaParcels';
import { selectedSelectedRpaFeatures } from './rpa-selectors';
import { saveRpaFeatures } from './rpa-utils';
import type { RPAImportFormValues } from './RPAImportForm';
import type { RPAState } from './rps-state';
import { rpaReducer } from './rps-state';

import './RPAImportContainer.css';

interface RPAImportContainerBaseProps {
    onFinish?: () => void;
    onBack?: () => void;
}

const RPAImportContainerBase = ({ onFinish, onBack }: RPAImportContainerBaseProps) => {
    const dispatchRTK = useAppDispatch();
    const { t } = useTranslation();
    const { farmUuid } = usePageParams();

    const onFinishHandler = () => {
        dispatchRTK(
            setMapView({
                type: 'full-extent',
            })
        );

        onFinish?.();
    };

    const onBackHandler = () => {
        dispatchRTK(
            setMapView({
                type: 'full-extent',
            })
        );
        onBack?.();
    };

    const [state, dispatch] = useReducer<Reducer<RPAState, any>>(rpaReducer, {
        selected: List(),
        ignored: List(),
        failed: List(),
    } as RPAState);
    const { pending, error, setPromise } = usePromise<Field[]>((fields) => {
        Segment.track('RPA fields created', { farmUuid: farmUuid });
        dispatchRTK(setFields(fields));

        const selectedFeatureIds = state.selected;
        if (fields.length === selectedFeatureIds.size) {
            onFinishHandler();
        } else {
            dispatch({ type: 'setFailed', payload: List(fields.map((f) => f.name)) });
        }
    });

    const createFields = (values: RPAImportFormValues) => {
        if (!farmUuid) return;

        if (!state.features || state.selected.size === 0) {
            return;
        }

        setPromise(saveRpaFeatures(farmUuid, selectedSelectedRpaFeatures(state), values));
    };

    const highlightFeature = (id?: string) => {
        dispatch({ type: 'highlightFeature', payload: id });
    };
    const deselectFeature = (id: string) => {
        dispatch({ type: 'featureClick', payload: id });
    };
    const setFeatureLabel = (id: string, label: string) => {
        dispatch({ type: 'setFeatureLabel', payload: { id, label } });
    };

    return (
        <TakeOverModal onClose={onBackHandler}>
            <div className="RPAImportContainer">
                <div className="RPAImportContainerWrapper">
                    <h2>
                        <img
                            src="https://integration-assets.fieldmargin.com/assets/646f758b-f7c1-4e3d-9bef-226793acea3c/rpa.jpg"
                            alt="Rural Payment Agency logo"
                            className="w-32"
                        />
                        {t('rpa_header')}
                    </h2>
                    {!state.features && (
                        <ImportRpaParcels
                            setRpaFeatures={(features) =>
                                dispatch({ type: 'setFeatures', payload: features })
                            }
                        />
                    )}
                    {state.features && (
                        <ImportRpaFields
                            rpaState={state}
                            featureClick={highlightFeature}
                            onHighlight={highlightFeature}
                            onDeselect={deselectFeature}
                            setFeatureLabel={setFeatureLabel}
                            onCreate={createFields}
                            createPending={pending}
                            createError={error}
                            onFinish={onFinishHandler}
                        />
                    )}
                </div>
            </div>
        </TakeOverModal>
    );
};

export { RPAImportContainerBase };
