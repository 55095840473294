import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import type { Farm } from '@fieldmargin/webapp-farms';
import { useSegmentMountTracking } from '@fieldmargin/webapp-reporting';
import Button from '@fieldmargin/webapp-styling/components/button';
import { getRemoteConfigNumberValue, RemoteConfigKeys } from 'lib/firebase/firebase-connection';
import Modal from 'modal/Modal';
import { useAppSelector } from 'system/store';

import { selectFreeFarmOwnerCount } from './farms-state';

interface FreeFarmLimitWarningModalProps {
    farm: Farm;
    onClose: VoidFunction;
}

const FreeFarmLimitWarningModal = ({ farm, onClose }: FreeFarmLimitWarningModalProps) => {
    const { t } = useTranslation();
    const allowedFreeFarms = getRemoteConfigNumberValue(RemoteConfigKeys.MAX_FREE_FARMS);
    const usedFreeFarms = useAppSelector(selectFreeFarmOwnerCount);

    useSegmentMountTracking('Free farm limit warning seen', {
        farmUuid: farm.uuid,
    });

    const handleContact = () => {
        window.Intercom &&
            window.Intercom(
                'showNewMessage',
                'Multifarm pricing\nI am using fieldmargin for multiple farms and would like more information on subscription options.'
            );
    };

    return (
        <Modal onClose={onClose}>
            <div className="bg-white p-10 w-96 text-center text-lg flex flex-col gap-6">
                <h2 className="text-xl mb-0">{t('farm_limit_reached')}</h2>
                <p className="mb-0">
                    {t('farm_limit_prompt', { sprintf: [usedFreeFarms, allowedFreeFarms] })}
                </p>
                <p className="mb-0">{t('paid_plan_prompt')}</p>
                <Button as={Link} to="/create-farm">
                    {t('label_continue')}
                </Button>
                <Button variant="outline" onClick={handleContact}>
                    {t('contact_support')}
                </Button>
            </div>
        </Modal>
    );
};

export default FreeFarmLimitWarningModal;
