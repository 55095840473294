import { useTranslation } from 'react-i18next';
import { Segment } from '@fieldmargin/webapp-reporting';
import { useToggle } from '@fieldmargin/webapp-state';
import { clsx } from 'clsx';
import { stopEditing } from 'farm-editing/farm-editing-state';
import { selectCurrentFarm } from 'farms/farms-state';
import { useAppDispatch, useAppSelector } from 'system/store';

import MeasureTool from './MeasureTool';
import rulerSvg from './ruler.svg';

import styles from './Measure.module.css';

const MeasureButton = () => {
    const { t } = useTranslation();
    const [open, toggleOpen] = useToggle(false);
    const dispatch = useAppDispatch();
    const farmUuid = useAppSelector(selectCurrentFarm).uuid;

    const toggleMeasureTool = () => {
        Segment.track(`Measure tool ${open ? 'closed' : 'opened'}`, { farmUuid });
        open && dispatch(stopEditing());
        toggleOpen();
    };

    return (
        <>
            <button
                onClick={toggleMeasureTool}
                className={clsx('bare-btn focus', styles.measureButton)}
            >
                <img src={rulerSvg} alt="Ruler icon" className={styles.measureIcon} />
                {t('label_measure', { defaultValue: 'Measure' })}
            </button>
            {open && <MeasureTool toggleMeasureTool={toggleMeasureTool} />}
        </>
    );
};

const MeasureButtonWrapper = () => {
    const shouldShow = useAppSelector(
        (state) =>
            state.farmEditingState.editingType === null ||
            state.farmEditingState.editingType === 'measure'
    );
    return shouldShow && <MeasureButton />;
};

export { MeasureButtonWrapper as MeasureButton };
