import type { ComponentProps } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import type { Farm } from '@fieldmargin/webapp-farms';
import Button from '@fieldmargin/webapp-styling/components/button';
import icon from 'components/icons/icon';
import { selectCurrentFarm } from 'farms/farms-state';
import type Field from 'fields/Field';
import { getFieldName } from 'fields/Field';
import { selectField } from 'fields/fields-selectors';
import withPageField from 'fields/withPageField';
import { isCreator } from 'lib/owner';
import { isNil, unless } from 'ramda';
import SidebarHeader from 'sidebar/modules/header/SidebarHeader';
import SidebarHeaderLink from 'sidebar/modules/header/SidebarHeaderLink';
import SidebarModule from 'sidebar/modules/SidebarModule';
import SidebarError from 'sidebar/SidebarError';
import type { AppState } from 'system/store';
import { selectIsFarmOwner } from 'team/farm-users-state';
import { selectUserId } from 'users/user-state';

import { useDeleteField } from '../field-hooks';

interface FieldDeletionBaseProps {
    farm: Farm;
    field: Field;
    parentField?: Field;
    canDelete: boolean;
}

const FieldDeletionBase = ({ farm, field, parentField, canDelete }: FieldDeletionBaseProps) => {
    const { t } = useTranslation();
    const { isPending, isError, onDeleteField } = useDeleteField(field);

    return (
        <div className="scrollable">
            <div className="non-scrolling">
                <SidebarHeader>
                    <SidebarHeaderLink to={`/farms/${farm.uuid}/fields/${field.uuid}`}>
                        {icon('back', 'dark-blue', 'mr-3')} {t('back')}
                    </SidebarHeaderLink>
                </SidebarHeader>
                {isError && (
                    <SidebarError
                        title={t('failed_to_delete')}
                        message={t('something_went_wrong')}
                    />
                )}
            </div>
            <div className="scrolling">
                <SidebarModule editing>
                    <h2 className="mb-0">{getFieldName(field, parentField)}</h2>
                </SidebarModule>
                {canDelete ? (
                    <SidebarModule editing>
                        <p className="text-lg">{t('message_delete_confirmation_field')}</p>
                        {parentField === undefined && (
                            <p className="text-lg">
                                <strong>{t('message_delete_confirmation_field_warning')}</strong>
                            </p>
                        )}
                        <Button variant="danger" onClick={onDeleteField} disabled={isPending}>
                            {t(isPending ? 'label_deleting' : 'label_delete')}
                        </Button>
                    </SidebarModule>
                ) : (
                    <SidebarModule editing>
                        <p className="text-lg text-error-text">{t('message_delete_unsupported')}</p>
                    </SidebarModule>
                )}
            </div>
        </div>
    );
};

function FieldDeletion({ ...props }: ComponentProps<typeof FieldDeletionBase>) {
    return props.field && <FieldDeletionBase {...props} />;
}

export default withPageField(
    connect((state: AppState, { field }: { field?: Field }) => ({
        farm: selectCurrentFarm(state),
        field,
        parentField: unless<string | undefined, Field | undefined>(
            isNil,
            (parentUuid) => selectField(state, parentUuid as string),
            field?.parentUuid
        ),
        canDelete:
            selectIsFarmOwner(state) ||
            (field !== undefined && isCreator(field, selectUserId(state))),
    }))(FieldDeletion)
);
