import type { ChangeEvent } from 'react';
import type { Farm } from '@fieldmargin/webapp-farms';
import { Segment } from '@fieldmargin/webapp-reporting';
import { usePromise } from '@fieldmargin/webapp-state';
import Checkbox from '@fieldmargin/webapp-styling/components/checkbox';
import c from 'classnames';
import { flow } from 'lodash';
import type FullOperation from 'operations/FullOperation';
import type { FullOperationField } from 'operations/OperationField';
import { fullOperationFieldToOperationField } from 'operations/OperationField';
import { saveOperationField } from 'operations/operations-save-helper';
import { setOperation } from 'operations/operations-state';
import { useAppDispatch } from 'system/store';

import { setOperationFieldIncomplete } from './mark-complete-utils';

interface MarkCompleteCheckboxProps {
    farm: Farm;
    fullOperation: FullOperation;
    operationField: FullOperationField;
    onChecked: VoidFunction;
    className?: string;
}

const MarkCompleteCheckbox = ({
    farm,
    fullOperation,
    operationField,
    onChecked,
    className,
}: MarkCompleteCheckboxProps) => {
    const dispatch = useAppDispatch();
    const { pending, setPromise } = usePromise<FullOperation>((fullOperation) => {
        Segment.track('Field operation reopened', {
            farmUuid: farm.uuid,
            jobUuid: fullOperation.uuid,
        });
        dispatch(setOperation(fullOperation));
    });

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        e.target.checked
            ? onChecked()
            : setPromise(
                  saveOperationField(
                      farm.uuid,
                      fullOperation,
                      flow(
                          fullOperationFieldToOperationField,
                          setOperationFieldIncomplete
                      )(operationField)
                  )
              );
    };
    return (
        <Checkbox
            id={operationField.field.uuid}
            className={c('relative', className)}
            onChange={handleChange}
            checked={operationField.completedDate !== undefined}
            disabled={pending}
        />
    );
};

export default MarkCompleteCheckbox;
