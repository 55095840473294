import { AplicationLayoutProvider, type ApplicationLayoutProps } from '@notes-web/layout';
import { AppRouterFieldmargin } from '@notes-web/router';
import { CreateFarmTipBox } from 'components/create-farm-tip-box';
import { MeasureButton } from 'measure/MeasureButton';
import PrintButton from 'print/PrintButton';
import TabBar from 'sidebar/tabs/TabBar';
import FarmTopNav from 'top-nav/FarmTopNav';

const createFarm: ApplicationLayoutProps['CreateFarm'] = {
    TipBox: CreateFarmTipBox,
};

const navigation: ApplicationLayoutProps['Navigation'] = TabBar;
const map: ApplicationLayoutProps['Map'] = {
    Button: {
        Measure: MeasureButton,
        Print: PrintButton,
    },
};
function FieldmarginApp() {
    return (
        <AplicationLayoutProvider
            CreateFarm={createFarm}
            Navigation={navigation}
            Header={FarmTopNav}
            Map={map}
        >
            <AppRouterFieldmargin />
        </AplicationLayoutProvider>
    );
}

export { FieldmarginApp };
