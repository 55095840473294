import { Outlet, type RouteObject } from 'react-router-dom';
import UserDeletePage from 'auth/general/UserDeletePage';
import UserDeleteSuccess from 'auth/general/UserDeleteSuccess';

import { APP_ROUTES } from '../types';

export const accountRoutes: RouteObject = {
    path: APP_ROUTES.Account.Root,
    element: <Outlet />,

    children: [
        {
            path: APP_ROUTES.Account.Delete,
            element: <UserDeletePage />,

            children: [
                {
                    path: APP_ROUTES.Account.Success,
                    element: <UserDeleteSuccess />,
                },
            ],
        },
    ],
};
