import { useTranslation } from 'react-i18next';
import type { Farm } from '@fieldmargin/webapp-farms';
import { useSegmentMountTracking } from '@fieldmargin/webapp-reporting';
import { useToggle } from '@fieldmargin/webapp-state';
import Button from '@fieldmargin/webapp-styling/components/button';
import Checkbox from '@fieldmargin/webapp-styling/components/checkbox';

import feraLogo from './fera-logo.svg';
import homologaLogo from './homologa-logo.png';
import lexagriLogo from './legagri-logo.jpg';

interface PesticideCheckDisclaimerProps {
    farm: Farm;
    onAgree: VoidFunction;
}

const PesticideCheckDisclaimer = ({ farm, onAgree }: PesticideCheckDisclaimerProps) => {
    const { t } = useTranslation();
    useSegmentMountTracking('Pesticide check disclaimer seen', {
        farmUuid: farm.uuid,
    });
    const [agreed, toggleAgreed] = useToggle(false);
    return (
        <>
            <h4>{t('pesticide_check_disclaimer_title')}</h4>
            <p>{t('peticide_check_disclaimer')}</p>

            <Checkbox
                id="pesticide-check-disclaimer"
                label={t('pesticide_check_disclaimer_agree')}
                checked={agreed}
                onChange={toggleAgreed}
                className="PesticideCheckDisclaimerCheckbox"
            />
            <Button disabled={!agreed} onClick={onAgree} className="mb-5 mx-auto block">
                {t('pesticide_check_disclaimer_confirm')}
            </Button>

            <p className="mb-0">{t('pesticide_check_disclaimer_powered_by')}</p>
            <div className="PesticideCheckLogos">
                <img src={homologaLogo} alt="Homologa logo" className="Homologa" />
                <img src={lexagriLogo} alt="Lexagri logo" className="Lexagri" />
                <img src={feraLogo} alt="Fera logo" className="Fera" />
            </div>
        </>
    );
};

export default PesticideCheckDisclaimer;
