import type { GeoPolygon } from '@fieldmargin/webapp-geo';
import { Segment } from '@fieldmargin/webapp-reporting';
import { createAction, handleActions } from '@fieldmargin/webapp-state';
import { loadingFarm } from 'farms/farm-loading-state';
import { fetchFieldHealthPolygons } from 'fields/fields-api';
import { List, Record } from 'immutable';

import type { SentinelHubMap } from './sentinel-hub-api';
import { SentinelHubStyle } from './sentinel-hub-api';

export const setSentinelHubMaps = createAction<VegetationMapsState, List<SentinelHubMap>>(
    'Set sentinel hub maps',
    (state, payload) => state.set('sentinelHubMaps', payload)
);
export const setVisibility = createAction<VegetationMapsState, VegetationMapsVisibility>(
    'Set vegetation maps visibility',
    (state, payload) => state.set('visibility', payload)
);

const pickSentinelHubMapInner = createAction<VegetationMapsState, SentinelHubMap | undefined>(
    'Pick sentinel hub map',
    (state, payload) =>
        state
            .set('visibleSentinelHubMap', payload)
            .set('visibility', payload ? VegetationMapsVisibility.ON : state.visibility)
);
export const pickSentinelHubMap = (value: SentinelHubMap | undefined) => {
    return (dispatch) => {
        Segment.track('Vegetation map viewed', {});
        dispatch(pickSentinelHubMapInner(value));
    };
};

export const pickStyle = createAction<VegetationMapsState, SentinelHubStyle>(
    'Pick style',
    (state, payload) => state.set('visibleStyle', payload)
);

const setFarmBoundaryPolygons = createAction<VegetationMapsState, List<GeoPolygon>>(
    'Set farm boundary polygons',
    (state, payload) => state.set('farmBoundaryPolygons', payload)
);
export const fetchFarmBoundaryPolygons = (farmUuid: string) => async (dispatch) => {
    dispatch(setFarmBoundaryPolygons(await fetchFieldHealthPolygons(farmUuid)));
};

export enum VegetationMapsVisibility {
    ON = 'on',
    OFF = 'off',
}

export const VegetationMapsState = Record({
    visibility: VegetationMapsVisibility.ON,
    sentinelHubMaps: null as List<SentinelHubMap> | null,
    visibleSentinelHubMap: undefined as SentinelHubMap | undefined,
    visibleStyle: SentinelHubStyle.NDVI,

    farmBoundaryPolygons: List<GeoPolygon>(),
});
export interface VegetationMapsState extends ReturnType<typeof VegetationMapsState> {}

export const vegetationMapsReducer = handleActions<VegetationMapsState>(
    VegetationMapsState(),
    [
        setSentinelHubMaps,
        setVisibility,
        pickSentinelHubMapInner,
        pickStyle,
        setFarmBoundaryPolygons,
    ],
    {
        [loadingFarm.toString()]: () => VegetationMapsState(),
    }
);
