import { useNavigate } from 'react-router-dom';
import type { Farm } from '@fieldmargin/webapp-farms';
import { deleteFarm, removeCurrentFarmUser } from '@fieldmargin/webapp-farms';
import { Segment } from '@fieldmargin/webapp-reporting';
import { usePromise } from '@fieldmargin/webapp-state';
import LocalStorageHelper from 'lib/storage/LocalStorageHelper';
import { useAppDispatch } from 'system/store';

import { removeFarm } from './farms-state';

export const useDeleteFarm = (
    farm: Farm,
    onDone?: VoidFunction
): [boolean, boolean, VoidFunction] => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { pending, error, setPromise } = usePromise<string>((farmUuid) => {
        onDone !== undefined && onDone();
        Segment.track('Farm deleted', {
            farmUuid: farmUuid,
        });
        LocalStorageHelper.removeItem('lastFarmUuid');
        navigate('/');
        dispatch(removeFarm(farmUuid));
    });

    const handleDelete = () => {
        setPromise(deleteFarm(farm.uuid));
    };

    return [pending, error, handleDelete];
};

export const useLeaveFarm = (
    farm: Farm,
    onDone?: VoidFunction
): [boolean, boolean, VoidFunction] => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { pending, error, setPromise } = usePromise(() => {
        onDone !== undefined && onDone();
        LocalStorageHelper.removeItem('lastFarmUuid');
        navigate('/');
        dispatch(removeFarm(farm.uuid));
    });
    const handleRemove = () => {
        setPromise(removeCurrentFarmUser(farm.uuid));
    };
    return [pending, error, handleRemove];
};
