import type { PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';
import { Segment } from '@fieldmargin/webapp-reporting';
import { clsx } from 'clsx';
import { useCurrentFarm } from 'hooks/useCurrentFarm';
import { useUpgradeLink } from 'hooks/useUpgradeLink';

import styles from './LinkUpgradePlan.module.css';

interface LinkUpgradePlanProps extends PropsWithChildren {
    className?: string;
}

function LinkUpgradePlan({ children, className }: LinkUpgradePlanProps) {
    const { currentFarm } = useCurrentFarm();
    const { upgradeLink } = useUpgradeLink();

    const onClickUpgrade = () => {
        Segment.track('Input warning limit upgrade clicked', { farmUuid: currentFarm.uuid });
    };

    return (
        <Link
            className={clsx(styles.upgradeLink, className)}
            to={upgradeLink}
            onClick={onClickUpgrade}
        >
            {children}
        </Link>
    );
}

export { LinkUpgradePlan };
