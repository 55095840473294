import type { ComponentProps, PropsWithChildren } from 'react';
import { DialogTrigger, Popover as _Popover } from 'react-aria-components';
import { clsx } from 'clsx';
import { ButtonBase } from 'components/button-base';
import { Checkbox } from 'components/checkbox';
import { Icon } from 'components/icon';
import { ChevronIcon } from 'icons/ChevronIcon';

import styles from './FilterSelect.module.css';

function Button({ className, ...props }: ComponentProps<(typeof ButtonBase)['Root']>) {
    return <ButtonBase.Root className={clsx(styles.button, className)} {...props} />;
}

function ButtonChevron({ children, ...props }: ComponentProps<typeof Button>) {
    return (
        <Button {...props}>
            <span>{children}</span>
            <Icon className={styles.chevronIcon}>
                <ChevronIcon />
            </Icon>
        </Button>
    );
}

function Popover({ children }: PropsWithChildren) {
    return (
        <_Popover placement="bottom left">
            <div className={styles.list}>{children}</div>
        </_Popover>
    );
}

export const FilterSelect = {
    Root: DialogTrigger,
    Button,
    Popover,
    Group: Checkbox.Group,
    Checkbox: Checkbox.Input,
    ButtonChevron,
};
