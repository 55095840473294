import { type ComponentProps, forwardRef, type ReactNode } from 'react';
import { Button as _Button } from 'react-aria-components';
import { clsx } from 'clsx';
import { Icon } from 'components/icon';

import styles from './ButtonBase.module.css';

interface ButtonRootProps extends ComponentProps<typeof _Button> {
    children: ReactNode;
    variant?: 'primary' | 'ouline-primary';
}

const Root = forwardRef<HTMLButtonElement, ButtonRootProps>(
    ({ className, variant = 'default', ...props }, ref) => {
        return (
            <_Button
                className={clsx(styles.button, className, {
                    [styles.primary]: variant === 'primary',
                    [styles.primaryOutline]: variant === 'ouline-primary',
                })}
                ref={ref}
                {...props}
            />
        );
    }
);
Root.displayName = 'Button.Root';

function ButtonIcon({ ...props }: ComponentProps<typeof Icon>) {
    return <Icon {...props} />;
}
ButtonIcon.displayName = 'Button.Icon';

export const ButtonBase = { Root, Icon: ButtonIcon };
