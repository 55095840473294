import { useTranslation } from 'react-i18next';
import { Outlet, type RouteObject, useOutletContext } from 'react-router-dom';
import { useIsFarmOwner } from 'hooks/selectors/src/useIsFarmOwner';
import { FieldInputReport } from 'reporting/field-input-report/FieldInputReport';
import GrossMarginByFieldReport from 'reporting/gross-margin/GrossMarginByFieldReport';
import { GrossMarginByUsageGraph } from 'reporting/gross-margin/GrossMarginByUsageGraph';
import GrossMarginByUsageReport from 'reporting/gross-margin/GrossMarginByUsageReport';
import GrossMarginByUsageTable from 'reporting/gross-margin/GrossMarginByUsageTable';
import GrossMarginReportContainer from 'reporting/gross-margin/GrossMarginReportContainer';
import InputReportContainer from 'reporting/input/InputReportContainer';
import InputCostsGraph from 'reporting/input-costs/InputCostsGraph';
import InputCostsReport from 'reporting/input-costs/InputCostsReport';
import InputCostsTable from 'reporting/input-costs/InputCostsTable';
import EditInputCosts from 'reporting/input-summary/edit-costs/EditInputCosts';
import InputSummary from 'reporting/input-summary/InputSummary';
import InputSummaryView from 'reporting/input-summary/InputSummaryView';
import NutrientsReportContainer from 'reporting/nutrients/NutrientsReportContainer';
import ReportingContainer from 'reporting/ReportingContainer';
import ReportingHome from 'reporting/ReportingHome';
import YieldReportGraph from 'reporting/yield/YieldReportGraph';
import YieldReporting from 'reporting/yield/YieldReporting';
import YieldReportTable from 'reporting/yield/YieldReportTable';
import ErrorMessage from 'view/ErrorMessage';

import { APP_ROUTES } from '../types';

function ErrorMesageReporting() {
    const { t } = useTranslation();
    return <ErrorMessage>{t('reporting_error_boundary')}</ErrorMessage>;
}

function ReportingProtectedRoutes() {
    const { isFarmOwner } = useIsFarmOwner();
    const context = useOutletContext();

    return isFarmOwner && <Outlet context={context} />;
}

export const reportingRoutes: RouteObject = {
    path: APP_ROUTES.Reporting.Root,
    element: <ReportingContainer />,

    children: [
        { index: true, element: <ReportingHome />, errorElement: <ErrorMesageReporting /> },

        {
            element: <ReportingProtectedRoutes />,
            // PROTECTED ROUTES
            children: [
                {
                    path: APP_ROUTES.Reporting.InputSummary,
                    element: <InputSummary />,

                    children: [
                        {
                            index: true,
                            element: <InputSummaryView />,
                        },
                        {
                            path: APP_ROUTES.Reporting.EditCosts,
                            element: <EditInputCosts />,
                        },
                    ],
                },
                {
                    path: APP_ROUTES.Reporting.Input,
                    element: <InputReportContainer />,
                },
                {
                    path: APP_ROUTES.Reporting.FieldInputSummary,
                    element: <FieldInputReport />,
                },
                {
                    path: APP_ROUTES.Reporting.InputCosts,
                    element: <InputCostsReport />,

                    children: [
                        {
                            index: true,
                            element: <InputCostsGraph />,
                        },
                        {
                            path: APP_ROUTES.Reporting.InputCostsTable,
                            element: <InputCostsTable />,
                        },
                    ],
                },
                {
                    path: APP_ROUTES.Reporting.Yeld,
                    element: <YieldReporting />,

                    children: [
                        {
                            index: true,
                            element: <YieldReportTable />,
                        },
                        {
                            path: APP_ROUTES.Reporting.Graph,
                            element: <YieldReportGraph />,
                        },
                    ],
                },
                {
                    path: APP_ROUTES.Reporting.GrossMargin,
                    element: <GrossMarginReportContainer />,

                    children: [
                        {
                            index: true,
                            element: <GrossMarginByFieldReport />,
                        },
                        {
                            path: APP_ROUTES.Reporting.GrossMarginUsage,
                            element: <GrossMarginByUsageReport />,
                            children: [
                                {
                                    index: true,
                                    element: <GrossMarginByUsageGraph />,
                                },
                                {
                                    path: APP_ROUTES.Reporting.GrossMarginUsageTable,
                                    element: <GrossMarginByUsageTable />,
                                },
                            ],
                        },
                    ],
                },
                {
                    path: APP_ROUTES.Reporting.Nutrients,
                    element: <NutrientsReportContainer />,
                },
            ],
        },
    ],
};
