import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import type { Farm } from '@fieldmargin/webapp-farms';
import { Segment } from '@fieldmargin/webapp-reporting';
import Button from '@fieldmargin/webapp-styling/components/button';
import SubmitButton from '@fieldmargin/webapp-styling/components/button/SubmitButton';
import Checkbox from '@fieldmargin/webapp-styling/components/checkbox';
import { selectCurrentFarm } from 'farms/farms-state';
import { useOperationDetailsActions } from 'hooks/actions';
import { useCurrentOperation } from 'hooks/useCurrentOperation';
import { Set } from 'immutable';
import { useOutOfDatePromise } from 'lib/hooks';
import { addOrRemoveInSet } from 'lib/immutil';
import type { MeasurementUnit } from 'lib/MeasurementUnit';
import type FullOperation from 'operations/FullOperation';
import { saveOperationFields } from 'operations/operations-save-helper';
import { setOperation } from 'operations/operations-state';
import { bindActionCreators } from 'redux';
import type { AppState } from 'system/store';
import {
    selectUserAreaMeasurementUnit,
    selectUserId,
    selectUserLengthMeasurementUnit,
    selectUserTemperatureUnit,
} from 'users/user-state';
import ErrorMessage from 'view/ErrorMessage';
import TakeOverModal from 'view/modal/TakeOverModal';
import OutOfDateErrorMessage from 'view/OutOfDateErrorMessage';

import { useEditableOperationDetailsContext } from '../details/useEditableOperationDetailsContext';

import EditOperationFieldsTable from './EditOperationFieldsTable';
import {
    getInitialFormValues,
    sanitiseFormValues,
    shouldTrackCompletion,
} from './operation-fields-util';

import './EditOperationFields.css';

interface EditOperationFieldsProps {
    // From redux
    farm: Farm;
    userId: number;
    areaMeasurementUnit: MeasurementUnit;
    lengthMeasurementUnit: MeasurementUnit;
    temperatureMeasurementUnit: string;
    setOperation: typeof setOperation;
}

export interface EditOperationFieldsFormValues {
    [k: string]: EditOperationFieldsFieldValues;
}

export interface EditOperationFieldsFieldValues {
    workArea: number;
    yieldRate?: number;
    yieldTotal?: number;
    completedDate?: Date | string;
    completedByUserId?: string;

    startDate?: Date;
    windSpeed?: number;
    windDirection?: string;
    temperature?: number;
    weatherNote?: string;
}

const EditOperationFields = ({
    farm,
    userId,
    areaMeasurementUnit,
    lengthMeasurementUnit,
    temperatureMeasurementUnit,
    setOperation,
}: EditOperationFieldsProps) => {
    const { t } = useTranslation();
    const { handleToggleEditingFields: onClose } = useOperationDetailsActions();
    const { fullOperation, operationFields, isHarvesting } = useEditableOperationDetailsContext();
    const { output } = useCurrentOperation();

    const [selected, setSelected] = useState<Set<string>>(Set());
    const [removed, setRemoved] = useState<Set<string>>(Set());
    const [pending, outofDate, error, setPromise] = useOutOfDatePromise<FullOperation>(
        (savedFullOperation) => {
            if (
                shouldTrackCompletion(
                    fullOperation.fields ?? Set(),
                    savedFullOperation.fields ?? Set()
                )
            ) {
                Segment.track('Field operation completed', {
                    farmUuid: farm.uuid,
                    jobUuid: savedFullOperation.uuid,
                });
            }
            setOperation(savedFullOperation);
            onClose();
        }
    );

    /**
     * If there are any items selected, select-all will clear the set, otherwise all items are
     * added.
     */
    const handleSelectAll = () => {
        setSelected(
            selected.size > 0 ? Set() : operationFields.map(({ field: { uuid } }) => uuid).toSet()
        );
    };

    const toggleSelected = (id: string) => {
        setSelected(addOrRemoveInSet(selected, id));
    };

    const allSelected = operationFields.size === selected.size;

    const handleSubmit = (values: EditOperationFieldsFormValues) => {
        setPromise(
            saveOperationFields(
                farm.uuid,
                fullOperation,
                sanitiseFormValues(
                    values,
                    removed,
                    areaMeasurementUnit,
                    lengthMeasurementUnit,
                    temperatureMeasurementUnit
                )
            )
        );
    };

    const methods = useForm<EditOperationFieldsFormValues>({
        mode: 'onChange',
        defaultValues: getInitialFormValues(
            operationFields,
            areaMeasurementUnit,
            lengthMeasurementUnit,
            temperatureMeasurementUnit
        ),
    });

    return (
        <TakeOverModal onClose={onClose}>
            <FormProvider {...methods}>
                <form
                    onSubmit={methods.handleSubmit(handleSubmit)}
                    className="scrollable h-full max-h-full"
                >
                    <header className="text-center non-scrolling pb-4">
                        <h2>{t('edit_fields')}</h2>
                        <p>{t('operation_edit_fields_desc')}</p>
                    </header>
                    <div className="scrolling">
                        <div className="mb-4">
                            <Checkbox
                                id="select-all-fields"
                                label={
                                    selected.size > 0
                                        ? t('deselect_all_fields')
                                        : t('select_all_fields')
                                }
                                onChange={handleSelectAll}
                                checked={allSelected}
                            />
                        </div>
                        <EditOperationFieldsTable
                            userId={userId}
                            operationFields={operationFields.filter(
                                (opField) => !removed.contains(opField.field.uuid)
                            )}
                            selectedFields={selected}
                            toggleFieldSelected={toggleSelected}
                            output={output}
                            includeYield={isHarvesting}
                            onRemoveField={(fieldUuid) => setRemoved(removed.add(fieldUuid))}
                        />
                    </div>
                    {error && (
                        <ErrorMessage className="mt-4 text-center">
                            {t('something_went_wrong')}
                        </ErrorMessage>
                    )}
                    {outofDate && <OutOfDateErrorMessage className="mt-4 text-center" />}
                    <div className="flex justify-center non-scrolling pt-4">
                        <Button
                            variant="danger-outline"
                            className="mr-4"
                            onClick={onClose}
                            disabled={pending}
                        >
                            {t('cancel')}
                        </Button>
                        <SubmitButton disabled={pending}>{t('save')}</SubmitButton>
                    </div>
                </form>
            </FormProvider>
        </TakeOverModal>
    );
};

export default connect(
    (state: AppState) => ({
        farm: selectCurrentFarm(state),
        userId: selectUserId(state),
        areaMeasurementUnit: selectUserAreaMeasurementUnit(state),
        lengthMeasurementUnit: selectUserLengthMeasurementUnit(state),
        temperatureMeasurementUnit: selectUserTemperatureUnit(state),
    }),
    (dispatch) => bindActionCreators({ setOperation }, dispatch)
)(EditOperationFields);
