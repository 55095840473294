import { APP_ROUTES } from '@notes-web/router/src/types';
import { useCurrentPageLocation } from 'hooks/useCurrentPageLocation';
import type { OperationActionType } from 'types';

const useOperationDetailsSelector = () => {
    const { currentPageLocation } = useCurrentPageLocation();

    const operationPage = (operation: OperationActionType) =>
        currentPageLocation.endsWith(operation);

    return {
        isEditingFields: operationPage(APP_ROUTES.Operations.EditFields),
        isAttachingRecordings: operationPage(APP_ROUTES.Operations.AddRecordings),
        isAttachingFuel: operationPage(APP_ROUTES.Operations.AddFuel),
        isAddingOutput: operationPage(APP_ROUTES.Operations.AddOutput),
    };
};

export { useOperationDetailsSelector };
