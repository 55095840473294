import type { RouteObject } from 'react-router-dom';
import FieldHistoryPage from 'fields/FieldHistoryPage';
import HerdHistoryPage from 'herd/HerdHistoryPage';
import NotePrintPage from 'notes/print/NotePrintPage';
import OperationPrintSheet from 'operations/print/OperationPrintSheet';

import { APP_ROUTES } from '../types';

export const printRoutes: RouteObject = {
    children: [
        {
            path: APP_ROUTES.Notes.Print,
            element: <NotePrintPage />,
        },
        {
            path: APP_ROUTES.Operations.Print,
            element: <OperationPrintSheet />,
        },
        {
            path: APP_ROUTES.Fields.Print,
            element: <FieldHistoryPage />,
        },
        {
            path: APP_ROUTES.Livestock.History,
            element: <HerdHistoryPage />,
        },
    ],
};
