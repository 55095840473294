import { createBrowserRouter, Navigate, type RouteObject, RouterProvider } from 'react-router-dom';
import { App, FieldmarginAuthenticatedContainer } from '@notes-web/app';
import AuthorizationCompletion from 'auth/AurthorizationCompletion';
import UserDeleteSuccess from 'auth/general/UserDeleteSuccess';
import FeatureFlagsPage from 'components/feature-flags/FeatureFlagsPage';

import { accountRoutes } from '../account/acount.routes';
import { authRoutes } from '../auth/auth.routes';
import { createFarmRoutes } from '../create-farm/_index';
import { farmsRoutes } from '../farms/_index.routes';
import { fieldsRoutes } from '../fields/fields.routes';
import { flagsRoutes } from '../flags/flags.routes';
import { joinRoutes } from '../join/join.routes';
import { mapRoutes } from '../map/map.routes';
import { organisationsRoutes } from '../organisations/organisations.routes';
import { APP_ROUTES } from '../types';

const rootRoutes: RouteObject[] = [
    {
        path: APP_ROUTES.Root,
        element: <App />,

        children: [
            {
                path: 'auth/complete',
                element: <AuthorizationCompletion />,
            },
            {
                path: 'account/delete/success',
                element: <UserDeleteSuccess />,
            },
            {
                path: 'flags',
                element: <FeatureFlagsPage />,
            },
            {
                children: [
                    {
                        path: APP_ROUTES.Root,
                        element: <FieldmarginAuthenticatedContainer />,

                        children: [
                            joinRoutes,
                            accountRoutes,
                            authRoutes,
                            flagsRoutes,
                            fieldsRoutes,
                            mapRoutes,
                            organisationsRoutes,
                            createFarmRoutes,
                            ...farmsRoutes,

                            // Catch-all route to handle unmatched routes within AuthenticatedContainer
                            {
                                path: '*',
                                element: <Navigate to={APP_ROUTES.Root} replace />,
                            },
                        ],
                    },
                ],
            },
        ],
    },
];

const router = createBrowserRouter(rootRoutes);

export function AppRouterFieldmargin() {
    return <RouterProvider router={router} />;
}
