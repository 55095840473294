import { useEffect } from 'react';
// @ts-ignore
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import type { Farm } from '@fieldmargin/webapp-farms';
import type { Extent, GeoPolygon } from '@fieldmargin/webapp-geo';
import { Segment } from '@fieldmargin/webapp-reporting';
import { usePromise } from '@fieldmargin/webapp-state';
import Button from '@fieldmargin/webapp-styling/components/button';
import c from 'classnames';
import { selectCurrentFarm } from 'farms/farms-state';
import type Field from 'fields/Field';
import type { List } from 'immutable';
import type { SentinelHubMap } from 'maps/vegetation/sentinel-hub-api';
import { getSentinelHubMapsForAreaApi } from 'maps/vegetation/sentinel-hub-api';
import type { VegetationMapsVisibility } from 'maps/vegetation/vegetation-maps-state';
import {
    fetchFarmBoundaryPolygons,
    setSentinelHubMaps,
    setVisibility,
} from 'maps/vegetation/vegetation-maps-state';
import { selectWholeFarmBoundary } from 'maps/vegetation/vegetation-maps-util';
import type { OnboardingProps } from 'onboarding/onboarding-state';
import { withOnboarding } from 'onboarding/onboarding-state';
import { bindActionCreators } from 'redux';
import EmptyState from 'sidebar/modules/EmptyState';
import SidebarModule from 'sidebar/modules/SidebarModule';
import VisibilityToggle from 'sidebar/visibility-toggle/VisibilityToggle';
import type { AppState } from 'system/store';
import { selectIsFarmOwner } from 'team/farm-users-state';
import { startTutorial } from 'tutorials/tutorials-state';
import { TutorialTypes } from 'tutorials/TutorialTypes';
import ErrorMessage from 'view/ErrorMessage';
import VegetationIcon from 'view/svg/VegetationIcon';

import fieldHealthImg from './field-health.png';
import FieldHealthUpgradeMessage from './FieldHealthUpgradeMessage';
import SentinelHubMapPicker from './SentinelHubMapPicker';

interface MapsListVegetationProps extends OnboardingProps {
    farm: Farm;
    farmBoundary: Extent | null;
    fields: List<Field> | null;

    sentinelHubMaps: List<SentinelHubMap> | null;
    setSentinelHubMaps: typeof setSentinelHubMaps;

    farmBoundaryPolygons: List<GeoPolygon>;
    fetchFarmBoundaryPolygons: typeof fetchFarmBoundaryPolygons;

    visibility: VegetationMapsVisibility;
    setVisibility: (value: VegetationMapsVisibility) => void;

    isFarmOwner: boolean;
    trialAvailable: boolean;

    startTutorial: (opts: { tutorial: string; step: string }) => void;
    activeTutorial: string | null;
}

const MapsListVegetation = ({
    farm,
    farmBoundary,
    sentinelHubMaps,
    setSentinelHubMaps,
    farmBoundaryPolygons,
    fetchFarmBoundaryPolygons,
    visibility,
    setVisibility,
    isFarmOwner,
    trialAvailable,
    startTutorial,
    activeTutorial,
    activeStep,
    skipOnboarding,
}: MapsListVegetationProps) => {
    const { t } = useTranslation();
    const { pending, error, setPromise } = usePromise<List<SentinelHubMap>>((sentinelHubMaps) => {
        setSentinelHubMaps(sentinelHubMaps);
        Segment.track('Vegetation maps loaded', {});
    });

    useEffect(() => {
        if (farm.plan.fieldHealth && !sentinelHubMaps && farmBoundary) {
            setPromise(getSentinelHubMapsForAreaApi(farmBoundary, farm.freeTrial));
        }
    }, [farm, sentinelHubMaps]);

    useEffect(() => {
        if (farm.plan.fieldHealth) fetchFarmBoundaryPolygons(farm.uuid);
    }, [farm]);

    return (
        <div
            className={c('MapsListVegetation', {
                forefront: activeTutorial === 'fieldHealth',
            })}
            onClick={() => {
                activeStep === 'fieldHealth' && skipOnboarding();
            }}
        >
            <div className="flex items-center bg-gray-100 p-5 separator-bottom">
                <VegetationIcon className="w-12 h-12 mr-5" fill="#2E804D" />
                <h4 className="mb-0 text-lg">{t('field_health')}</h4>
                {sentinelHubMaps && (
                    <VisibilityToggle
                        active={visibility}
                        onSelect={setVisibility}
                        className="ml-auto"
                    />
                )}
            </div>
            {!farm.plan.fieldHealth ? (
                <FieldHealthUpgradeMessage
                    farm={farm}
                    isFarmOwner={isFarmOwner}
                    trialAvailable={trialAvailable}
                />
            ) : (
                <div>
                    {!sentinelHubMaps && (
                        <SidebarModule editing>
                            {error && <ErrorMessage>{t('something_went_wrong')}</ErrorMessage>}
                            {pending && (
                                <p>
                                    <em>{t('field_health_loading')}</em>
                                </p>
                            )}
                            {farmBoundary && !pending && (
                                <Button
                                    onClick={() => {
                                        farmBoundary &&
                                            setPromise(
                                                getSentinelHubMapsForAreaApi(
                                                    farmBoundary,
                                                    farm.freeTrial
                                                )
                                            );
                                    }}
                                    disabled={pending}
                                >
                                    {t('field_health_manual_load')}
                                </Button>
                            )}
                            {!farmBoundary && !pending && (
                                <EmptyState
                                    header={t('field_health_no_fields_title')}
                                    image={fieldHealthImg}
                                >
                                    <p>{t('field_health_no_fields_desc')}</p>
                                    <p>
                                        <Button
                                            variant="outline"
                                            onClick={() =>
                                                startTutorial({
                                                    tutorial: TutorialTypes.FIELD,
                                                    step: 'new-field-button',
                                                })
                                            }
                                        >
                                            {t('field_list_empty_title_web')}
                                        </Button>
                                    </p>
                                </EmptyState>
                            )}
                        </SidebarModule>
                    )}
                    {sentinelHubMaps && farmBoundaryPolygons.size > 0 && (
                        <>
                            <SentinelHubMapPicker />
                            <div className="p-5">
                                <p>{t('field_health_ndvi_desc1')}</p>
                                <p>{t('field_health_ndvi_desc2')}</p>
                            </div>
                        </>
                    )}
                </div>
            )}
        </div>
    );
};

export default connect(
    (state: AppState) => ({
        farm: selectCurrentFarm(state),
        farmBoundary: selectWholeFarmBoundary(state),
        sentinelHubMaps: state.vegetationMapsState.sentinelHubMaps,
        farmBoundaryPolygons: state.vegetationMapsState.farmBoundaryPolygons,
        visibility: state.vegetationMapsState.visibility,

        isFarmOwner: selectIsFarmOwner(state),
        trialAvailable: state.membershipState.trialAvailable,
    }),
    (dispatch) =>
        bindActionCreators(
            {
                setSentinelHubMaps,
                fetchFarmBoundaryPolygons,
                setVisibility,
                startTutorial,
            },
            dispatch
        )
)(withOnboarding(MapsListVegetation));
