import type Feature from 'features/Feature';
import { getNiceFeatureArea } from 'features/Feature';
import { useCurrentFarm } from 'hooks/useCurrentFarm';
import SizeBubble from 'view/molecules/size-bubble/SizeBubble';
import RememberingLink from 'view/RememberingLink';
import FormattedGeoData from 'view/units/FormattedGeoData';

interface FeaturePreviewProps {
    feature: Feature;
}

const FeaturePreview = ({ feature }: FeaturePreviewProps) => {
    const { currentFarm } = useCurrentFarm();
    const sizes = getNiceFeatureArea(feature);

    return (
        <RememberingLink
            to={`/farms/${currentFarm.uuid}/features/${feature.uuid}`}
            className="flex pr-5 pl-12 py-4 bordered-t focus ring-inset"
        >
            <div className="w-2/3">{feature.name}</div>
            <div className="flex-grow flex justify-end text-xs text-gray-500">
                <div className="mx-5">
                    <SizeBubble>
                        <FormattedGeoData sizes={sizes} />
                    </SizeBubble>
                </div>
                <i className="ion-chevron-right" />
            </div>
        </RememberingLink>
    );
};

export default FeaturePreview;
