import { useTranslation } from 'react-i18next';
import Markdown from 'react-markdown';
import type { Farm } from '@fieldmargin/webapp-farms';
import { Segment, useSegmentMountTracking } from '@fieldmargin/webapp-reporting';
import Button from '@fieldmargin/webapp-styling/components/button';
import type { List } from 'immutable';
import Modal from 'modal/Modal';
import type { CodedItem, FieldAndCurrentUsage, SingleParamVoidFunction } from 'system/types';
import ApplyCheckboxFilter from 'view/filters/ApplyCheckboxFilter';

import type { OperationRecording } from '../details/recording/operation-recording-utils';
import type { PesticideCheckFilter } from '../details/recording/pesticide-check-state';
import { pesticideCheckHasNoFilters } from '../details/recording/pesticide-check-state';

import feraLogo from './fera-logo.svg';
import homologaLogo from './homologa-logo.png';
import lexagriLogo from './legagri-logo.jpg';
import type { PesticideCheckResponse } from './PesticideCheck';
import { PesticideCheckStatus } from './PesticideCheck';
import PesticideCheckCropFilter from './PesticideCheckCropFilter';
import PesticideCheckCropStageFilter from './PesticideCheckCropStageFilter';
import PesticideCheckPestFilter from './PesticideCheckPestFilter';
import PesticideCheckResults from './PesticideCheckResults';

import './PesticideCheckModal.css';

interface PesticideCheckResultsModalProps {
    // From parent
    farm: Farm;
    onClose: VoidFunction;
    operationRecordings: List<OperationRecording>;
    fields: FieldAndCurrentUsage[];
    pesticideCheckResponse: PesticideCheckResponse;
    filterValues: PesticideCheckFilter;
    setStatus: SingleParamVoidFunction<PesticideCheckStatus[]>;
    setPest: SingleParamVoidFunction<string | undefined>;
    setCropStage: SingleParamVoidFunction<string | undefined>;
    setCrop: SingleParamVoidFunction<CodedItem | undefined>;
}

const PesticideCheckResultsModal = ({
    farm,
    onClose,
    operationRecordings,
    fields,
    pesticideCheckResponse,
    filterValues,
    setStatus,
    setPest,
    setCropStage,
    setCrop,
}: PesticideCheckResultsModalProps) => {
    const { t } = useTranslation();
    useSegmentMountTracking('Pesticide check results seen', {
        farmUuid: farm.uuid,
    });
    const statusFilter = filterValues.status?.map((status) => status.toLowerCase()) ?? [];
    const handleStatusFilterApply = (status: string[]) => {
        Segment.track('Pesticide check status filter used', {
            farmUuid: farm.uuid,
        });
        setStatus(
            status.map(
                (status) => PesticideCheckStatus[status.toUpperCase()] as PesticideCheckStatus
            )
        );
    };

    const handleClose = () => {
        Segment.track('Pesticide check results acknowledged', {
            farmUuid: farm.uuid,
        });
        onClose();
    };

    return (
        <Modal onClose={onClose} className="PesticideCheckResultsModalWrapper">
            <div className="PesticideCheckResultsModal">
                <h2>{t('pesticide_check_result_title')}</h2>
                <Markdown>{t('pesticide_check_result_desc')}</Markdown>
                <div className="PesticideCheckResultsFilters">
                    <div className="PesticideCheckResultsFilter">
                        <span>{t('filter')}</span>
                        <ApplyCheckboxFilter
                            emptyMsg={t('pesticide_check_result_result_filter_none')}
                            onApply={handleStatusFilterApply}
                            selectedItemIds={statusFilter}
                            items={[
                                {
                                    id: 'fail',
                                    title: t('pesticide_check_result_result_filter_fail'),
                                },
                                {
                                    id: 'warning',
                                    title: t('pesticide_check_result_result_filter_warnings'),
                                },
                                {
                                    id: 'pass',
                                    title: t('pesticide_check_result_result_filter_no_error'),
                                },
                            ]}
                        />
                    </div>
                    {!pesticideCheckHasNoFilters(pesticideCheckResponse) && (
                        <>
                            <div className="PesticideCheckResultsFilter">
                                <span>{t('pesticide_check_result_crop_filter_title')}</span>
                                <PesticideCheckCropFilter
                                    farm={farm}
                                    crops={pesticideCheckResponse.filters.crops}
                                    selected={filterValues.crop}
                                    setCrop={setCrop}
                                />
                            </div>
                            <div className="PesticideCheckResultsFilter">
                                <span>{t('pesticide_check_result_pest_filter_title')}</span>
                                <PesticideCheckPestFilter
                                    farm={farm}
                                    pests={pesticideCheckResponse.filters.pests}
                                    selected={filterValues.pest}
                                    setPest={setPest}
                                />
                            </div>
                            <div className="PesticideCheckResultsFilter">
                                <span>{t('pesticide_check_result_stage_filter_title')}</span>
                                <PesticideCheckCropStageFilter
                                    farm={farm}
                                    cropStages={pesticideCheckResponse.filters.cropStages}
                                    selected={filterValues.cropStage}
                                    setCropStage={setCropStage}
                                />
                            </div>
                        </>
                    )}
                </div>
                <div className="PesticideCheckResults">
                    {operationRecordings.map((operationRecording, i) => (
                        <PesticideCheckResults
                            key={i}
                            input={operationRecording.input}
                            fields={fields}
                            pesticideCheckResponse={pesticideCheckResponse}
                            filters={filterValues}
                        />
                    ))}
                </div>
                <Button className="PesticideCheckResultsConfirm" onClick={handleClose}>
                    {t('pesticide_check_result_acknowledge')}
                </Button>
                <div className="mx-auto">
                    <p className="mb-0">{t('pesticide_check_disclaimer_powered_by')}</p>
                    <div className="PesticideCheckLogos ">
                        <img src={homologaLogo} alt="Homologa logo" className="Homologa" />
                        <img src={lexagriLogo} alt="Lexagri logo" className="Lexagri" />
                        <img src={feraLogo} alt="Fera logo" className="Fera" />
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default PesticideCheckResultsModal;
