import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import type { Farm } from '@fieldmargin/webapp-farms';
import { Segment } from '@fieldmargin/webapp-reporting';
import { usePromise } from '@fieldmargin/webapp-state';
import Button from '@fieldmargin/webapp-styling/components/button';
import SubmitButton from '@fieldmargin/webapp-styling/components/button/SubmitButton';
import { addDays } from 'date-fns';
import { AttachmentType } from 'farm-editing/attachments';
import { selectEditingAttachmentsByType, stopEditing } from 'farm-editing/farm-editing-state';
import { startMovingHerd } from 'farm-editing/start-editing-reducer';
import { selectCurrentFarm } from 'farms/farms-state';
import type Field from 'fields/Field';
import { selectFieldsWithFullNames } from 'fields/fields-selectors';
import type { HerdLocation } from 'herd/Herd';
import type Herd from 'herd/Herd';
import { saveHerdLocation } from 'herd/herd-api';
import { setHerd } from 'herd/herd-state';
import withPageHerd from 'herd/withPageHerd';
import type { List, Set } from 'immutable';
import { useShowHintOnMount } from 'lib/firebase/firebase-hints-api';
import { uuidMatches } from 'lib/fp-helpers';
import { bindActionCreators } from 'redux';
import SidebarHeader from 'sidebar/modules/header/SidebarHeader';
import SidebarModule from 'sidebar/modules/SidebarModule';
import SidebarError from 'sidebar/SidebarError';
import type { AppState } from 'system/store';
import { changeModal } from 'system/ui-state';
import DateAndTimeField from 'view/form/hook/DateAndTimeField';
import SmallPill from 'view/SmallPill';

import { selectHerdField } from '../herd-ui-utils';

interface HerdMoveProps {
    farm: Farm;
    year: number;
    fields: List<Field>;
    herd?: Herd;

    startMovingHerd: typeof startMovingHerd;
    stopEditing: VoidFunction;
    editingFieldUuids: Set<string>;

    changeModal: typeof changeModal;
    setHerd: typeof setHerd;
}

interface FormValues {
    moveDate: Date;
    field?: string;
}

const HerdMove = ({
    farm,
    year,
    fields,
    herd,
    startMovingHerd,
    stopEditing,
    editingFieldUuids,
    changeModal,
    setHerd,
}: HerdMoveProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    useShowHintOnMount('herdMove', () => changeModal({ name: 'herd-move' }), 2000);
    const methods = useForm<FormValues>({
        defaultValues: {
            moveDate: new Date(),
        },
    });

    const { pending, error, setPromise } = usePromise<HerdLocation>((newLocation) => {
        if (herd) {
            setHerd({ ...herd, herdLocation: newLocation });
            Segment.track('Herd moved', { farmUuid: farm.uuid, herdUuid: herd.uuid });
            navigate(`/farms/${farm.uuid}/livestock/${herd.uuid}`);
        }
    });

    useEffect(() => {
        startMovingHerd(herd);
        return () => {
            stopEditing();
        };
    }, []);

    useEffect(() => {
        if (editingFieldUuids.size > 0) {
            methods.setValue('field', editingFieldUuids.first());
        }
    }, [editingFieldUuids]);

    if (herd === undefined) {
        return <Navigate to={`/farms/${farm.uuid}/livestock`} />;
    }

    const handleSubmit = (values: FormValues) => {
        setPromise(
            saveHerdLocation(farm.uuid, year, {
                fieldUuid: values.field,
                herdUuid: herd.uuid,
                latest: true,
                moveDate: values.moveDate,
                size: herd.size,
            })
        );
    };

    const currentField = selectHerdField(fields, herd);
    const newFieldUuid = editingFieldUuids.first<string>();
    const newField = newFieldUuid ? fields.find(uuidMatches(newFieldUuid)) : undefined;

    return (
        <FormProvider {...methods}>
            <form className="scrollable" onSubmit={methods.handleSubmit(handleSubmit)}>
                <div className="non-scrolling">
                    <SidebarHeader className="px-5">
                        <Button
                            as={Link}
                            to={`/farms/${farm.uuid}/livestock/${herd.uuid}`}
                            variant="danger-outline"
                            small
                        >
                            {t('cancel')}
                        </Button>
                        <SubmitButton small disabled={pending}>
                            {t(pending ? 'livestock_details_moving' : 'livestock_details_move')}
                        </SubmitButton>
                    </SidebarHeader>
                    {error && (
                        <SidebarError
                            title={t('failed_to_save')}
                            message={t('something_went_wrong')}
                        />
                    )}
                </div>
                <div className="scrolling">
                    <SidebarModule editing>
                        <h4 className="mb-1">{t('livestock_details_location')}</h4>
                        {currentField ? (
                            <SmallPill className="mb-4">{currentField.name}</SmallPill>
                        ) : (
                            <p className="mb-4">
                                <em className="text-gray-550">
                                    {t('livestock_details_location_no_location')}
                                </em>
                            </p>
                        )}
                        <h4 className="mb-0">{t('livestock_move_new_location')}</h4>
                        {newField ? (
                            <SmallPill>{newField.name}</SmallPill>
                        ) : (
                            <p>
                                <em className="text-gray-550">
                                    {t('livestock_move_new_location_none')}
                                </em>
                            </p>
                        )}
                    </SidebarModule>
                    <SidebarModule editing>
                        <strong className="mr-8">{herd.name}</strong> {herd.size}
                    </SidebarModule>
                    <SidebarModule editing>
                        <h4>{t('livestock_details_move_date')}</h4>
                        <DateAndTimeField
                            field="moveDate"
                            openDirection="top"
                            allowDelete={false}
                            maxDate={addDays(new Date(), 1)}
                        />
                    </SidebarModule>
                </div>
            </form>
        </FormProvider>
    );
};

export default connect(
    (state: AppState) => ({
        farm: selectCurrentFarm(state),
        year: state.yearsState.currentYear,
        fields: selectFieldsWithFullNames(state),
        editingFieldUuids: selectEditingAttachmentsByType(state, AttachmentType.FIELD),
    }),
    (dispatch) =>
        bindActionCreators({ startMovingHerd, stopEditing, changeModal, setHerd }, dispatch)
)(withPageHerd(HerdMove));
