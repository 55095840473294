import type { RouteObject } from 'react-router-dom';
import TeamContainer from 'team/sidebar/TeamContainer';
import ErrorMessage from 'view/ErrorMessage';

import { APP_ROUTES } from '../types';

export const teamRoutes: RouteObject = {
    path: APP_ROUTES.Team.Root,

    errorElement: (
        <ErrorMessage className="p-5">
            Something went wrong when viewing team data, please refresh the page.
        </ErrorMessage>
    ),
    children: [
        {
            index: true,
            element: <TeamContainer />,
        },
    ],
};
