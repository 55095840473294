import type { MouseEvent, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import type FarmIntegration from 'integrations/FarmIntegration';
import type FarmIntegrationSensors from 'sensors/FarmIntegrationSensors';
import { canGraph } from 'sensors/sensors-util';
import type SensorSummary from 'sensors/SensorSummary';
import ViewLocationLink from 'sidebar/modules/ViewLocationLink';
import type { changeModal } from 'system/ui-state';

import SensorListSensor from './SensorListSensor';
import SensorsListGroupHeader from './SensorsListGroupHeader';

interface SensorListIntegrationGroupProps {
    farmIntegrationSensors: FarmIntegrationSensors;
    farmIntegration: FarmIntegration;
    fetchIntegrationSensorsPending: boolean;
    changeModal: typeof changeModal;
}

const SensorListIntegrationGroup = ({
    farmIntegrationSensors,
    farmIntegration,
    fetchIntegrationSensorsPending,
    changeModal,
}: SensorListIntegrationGroupProps) => {
    const { t } = useTranslation();
    const groupName = farmIntegration ? farmIntegration.name : 'Unknown';
    const logoUri = farmIntegration ? farmIntegration.logoUri : '';

    const sensorGroupActive = !!(
        farmIntegrationSensors.sensors && farmIntegrationSensors.sensors.find((s) => s.active)
    );

    const handleShowGraph = (sensor: SensorSummary, e: MouseEvent) => {
        e.preventDefault();
        changeModal({
            name: 'sensor-history',
            props: { sensor },
        });
    };

    const renderLinks = (sensor: SensorSummary) => {
        const links: ReactNode[] = [];

        const showGraphLink =
            sensor.metrics &&
            sensor.metrics.reduce(
                (showGraphLink, metric) => showGraphLink || canGraph(metric.type),
                false
            );

        if (showGraphLink) {
            links.push(
                <button
                    key="history"
                    onClick={(e) => handleShowGraph(sensor, e)}
                    className="bare-btn inline-block mr-4 underline"
                >
                    {t('sensors_show_history')}
                </button>
            );
        }
        if (sensor.getLocation()) {
            links.push(<ViewLocationLink key="view" location={sensor.getLocation()} />);
        }
        if (!sensor.upstreamLocation) {
            links.push(
                <Link
                    key="set-location"
                    to={`${sensor.id}/edit-location`}
                    className="inline-block mr-4 underline text-sm"
                >
                    {t(
                        sensor.overrideLocation
                            ? 'create_note_edit_location'
                            : 'sensors_set_location'
                    )}
                </Link>
            );
        }
        return links;
    };

    let content: ReactNode = null;
    if (!farmIntegrationSensors.sensors || !farmIntegrationSensors.fetchSuccessful) {
        content = (
            <div className="p-5">
                {t('sensors_connected_fetch_error', { sprintf: [groupName] })}
            </div>
        );
    } else if (farmIntegrationSensors.sensors && !farmIntegrationSensors.sensors.size) {
        content = <div className="p-5">{t('sensors_connected_empty')}</div>;
    } else {
        content = farmIntegrationSensors.sensors
            .sortBy((sensor) => (sensor.active ? 'a' : 'b') + sensor.getName().toLowerCase())
            .map((sensor) => (
                <SensorListSensor
                    key={sensor.id}
                    sensor={sensor}
                    active={sensor.active}
                    renderLinks={renderLinks}
                />
            ));
    }

    return (
        <div>
            <SensorsListGroupHeader
                name={groupName}
                logoUri={logoUri}
                active={sensorGroupActive}
                updating={fetchIntegrationSensorsPending}
            />
            {content}
        </div>
    );
};

export default SensorListIntegrationGroup;
