import type { RouteObject } from 'react-router-dom';
import { NoteDeletion } from 'notes/sidebar/delete/NoteDeletion';
import { NoteDetails } from 'notes/sidebar/details/NoteDetails';
import NoteList from 'notes/sidebar/list/NoteList';
import NoteNew from 'notes/sidebar/new/NoteNew';
import NoteLoader from 'notes/sidebar/NoteLoader';
import ErrorMessage from 'view/ErrorMessage';

import { APP_ROUTES } from '../types';

export const notesRoutes: RouteObject = {
    path: APP_ROUTES.Notes.Root,

    children: [
        {
            index: true,
            element: <NoteList />,
            errorElement: (
                <ErrorMessage className="p-5">
                    Something went wrong when viewing activites, please refresh the page.
                </ErrorMessage>
            ),
        },

        {
            path: APP_ROUTES.Notes.New,
            element: <NoteNew />,
            errorElement: (
                <ErrorMessage className="p-5">
                    Something went wrong when creating a note, please refresh the page.
                </ErrorMessage>
            ),
        },
        {
            path: APP_ROUTES.Notes.Note,
            element: <NoteLoader />,
            errorElement: (
                <ErrorMessage className="p-5">
                    Something went wrong when viewing a note, please refresh the page.
                </ErrorMessage>
            ),
            children: [
                { index: true, element: <NoteDetails /> },
                {
                    path: APP_ROUTES.Notes.Deleted,
                    element: <NoteDeletion />,
                },
            ],
        },
    ],
};
