import type { ComponentProps } from 'react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Navigate } from 'react-router-dom';
import type { Farm } from '@fieldmargin/webapp-farms';
import { useToggle } from '@fieldmargin/webapp-state';
import icon from 'components/icons/icon';
import { setMapGroupVisibility } from 'farm-editing/farm-editing-state';
import { selectCurrentFarm } from 'farms/farms-state';
import { useDateFormat } from 'hooks/useDateFormat';
import type FarmIntegration from 'integrations/FarmIntegration';
import { uuidMatches } from 'lib/fp-helpers';
import type MapGroup from 'maps/farm/MapGroup';
import { getMapGroupTotalExtent } from 'maps/farm/MapGroup';
import { updateFarmMapApi } from 'maps/farm/maps-api';
import { setMapGroup } from 'maps/farm/maps-state';
import withPageMapGroup from 'maps/farm/withPageMapGroup';
import { bindActionCreators } from 'redux';
import EditableDateAndTime from 'sidebar/modules/date/EditableDateAndTime';
import SidebarHeader from 'sidebar/modules/header/SidebarHeader';
import SidebarHeaderLink from 'sidebar/modules/header/SidebarHeaderLink';
import SidebarHeaderMenu from 'sidebar/modules/header/SidebarHeaderMenu';
import SidebarHeaderMenuItem from 'sidebar/modules/header/SidebarHeaderMenuItem';
import ViewOnMap from 'sidebar/modules/shapes/components/ViewOnMap';
import SidebarModule from 'sidebar/modules/SidebarModule';
import EditableTitle from 'sidebar/modules/title/EditableTitle';
import type { AppState } from 'system/store';
import { VisibilityOptions } from 'system/types';
import Fog from 'view/Fog';

interface MapGroupDetailsBaseProps {
    farm: Farm;
    mapGroup: MapGroup;
    farmIntegration?: FarmIntegration;
    setMapGroupVisibility: typeof setMapGroupVisibility;
    setMapGroup: typeof setMapGroup;
}

const MapGroupDetailsBase = ({
    farm,
    mapGroup,
    farmIntegration,
    setMapGroupVisibility,
    setMapGroup,
}: MapGroupDetailsBaseProps) => {
    const { t } = useTranslation();
    const { format } = useDateFormat();
    const [menuOpen, toggleMenuOpen] = useToggle(false);

    const extent = getMapGroupTotalExtent(mapGroup);

    const handleView = () => {
        setMapGroupVisibility({
            mapGroup,
            value: VisibilityOptions.ON,
        });
    };

    const handleSaveTitle = useCallback(
        (name: string) => updateFarmMapApi(farm.uuid, mapGroup.set('name', name)).then(setMapGroup),
        [farm, mapGroup, setMapGroup]
    );

    const handleSaveDate = useCallback(
        (timestamp: Date | null) =>
            updateFarmMapApi(farm.uuid, mapGroup.set('timestamp', timestamp ?? new Date())).then(
                setMapGroup
            ),
        [farm, mapGroup, setMapGroup]
    );

    return (
        <div className="map-group-details scrollable">
            {menuOpen && <Fog blue className="top-14" onClick={toggleMenuOpen} />}
            <div className="non-scrolling">
                <SidebarHeader className="relative">
                    {menuOpen && (
                        <Fog
                            blue
                            className="w-3/4"
                            style={{ bottom: -1 }}
                            onClick={toggleMenuOpen}
                        />
                    )}
                    <SidebarHeaderLink to={`/farms/${farm.uuid}/maps`}>
                        {icon('back', 'dark-blue', 'mr-3')} {t('back')}
                    </SidebarHeaderLink>
                    <SidebarHeaderMenu open={menuOpen} onToggleOpen={toggleMenuOpen}>
                        <SidebarHeaderMenuItem>
                            <Link
                                to={`/farms/${farm.uuid}/maps/${mapGroup.uuid}/delete`}
                                className="text-error-text"
                            >
                                {icon('remove', 'red', 'mr-3')} {t('label_delete')}
                            </Link>
                        </SidebarHeaderMenuItem>
                    </SidebarHeaderMenu>
                </SidebarHeader>
            </div>
            <div className="scrolling">
                <EditableTitle label="Map name" value={mapGroup.name} onSave={handleSaveTitle} />
                <SidebarModule>
                    <h4>{t('location')}</h4>
                    {extent && <ViewOnMap extent={extent} onClick={handleView} />}
                </SidebarModule>
                <EditableDateAndTime
                    label={t('sensors_date')}
                    value={mapGroup.timestamp}
                    onSave={handleSaveDate}
                    required
                    openDirection="bottom"
                >
                    {format(mapGroup.timestamp, 'do MMM yyyy, HH:mm')}
                </EditableDateAndTime>
                {farmIntegration && (
                    <SidebarModule>
                        <h4>{t('created_by')}</h4>
                        <div>{farmIntegration.name}</div>
                    </SidebarModule>
                )}
            </div>
        </div>
    );
};

function MapGroupDetails({ ...props }: ComponentProps<typeof MapGroupDetailsBase>) {
    if (props.mapGroup === undefined) {
        return <Navigate to={`/farms/${props.farm.uuid}/maps`} />;
    } else {
        return <MapGroupDetailsBase {...props} />;
    }
}

export default withPageMapGroup(
    connect(
        (state: AppState, { mapGroup }: { mapGroup?: MapGroup }) => ({
            farm: selectCurrentFarm(state),
            mapGroup,
            farmIntegration:
                mapGroup !== undefined
                    ? state.integrationsState.farmIntegrations?.find(
                          uuidMatches(mapGroup.farmIntegrationUuid)
                      )
                    : undefined,
        }),
        (dispatch) =>
            bindActionCreators(
                {
                    setMapGroupVisibility,
                    setMapGroup,
                },
                dispatch
            )
    )(MapGroupDetails)
);
