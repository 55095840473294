import { Segment } from '@fieldmargin/webapp-reporting';
import { createAction, handleActions } from '@fieldmargin/webapp-state';
import type { LoadedFarmSuccessPayload } from 'farms/farm-loading-state';
import { loadedFarmSuccess } from 'farms/farm-loading-state';
import { selectCurrentFarm } from 'farms/farms-state';
import { List, Record } from 'immutable';
import LocalStorageHelper from 'lib/storage/LocalStorageHelper';
import { dec } from 'ramda';
import type { Dispatch } from 'redux';
import { createSelector } from 'reselect';
import type { AppState } from 'system/store';
import { changeModal } from 'system/ui-state';

import { getDefaultYear } from './years-utils';

export interface ChangeCurrentYearPayload {
    farmUuid: string;
    year: number;
}
export const changeCurrentYearAction = createAction<YearsState, number>(
    'changeCurrentYear',
    (state, payload) => state.set('currentYear', payload)
);

export const changeCurrentYear = (payload: ChangeCurrentYearPayload) => {
    return (dispatch: Dispatch<any>) => {
        dispatch(changeCurrentYearAction(payload.year));
        saveDefaultYear(payload.farmUuid, payload.year);
        dispatch(showYearCopyModal());
        Segment.track('Switched year', { farmUuid: payload.farmUuid, year: payload.year });
    };
};

export const showYearCopyModal = () => (dispatch: Dispatch<any>, getState: () => AppState) => {
    if (canCopyYearData(getState())) dispatch(changeModal({ name: 'fields-year-copy' }));
};

const YearsState = Record({
    years: null as List<number> | null,
    currentYear: null as number | null,
});
interface YearsState extends ReturnType<typeof YearsState> {}
export default YearsState;

const saveDefaultYear = (farmUuid: string, year: number) => {
    LocalStorageHelper.setItem(`farm-${farmUuid}-year`, `${year}`);
};

export const yearsReducer = handleActions<YearsState>(YearsState(), [changeCurrentYearAction], {
    [loadedFarmSuccess.toString()]: (state, payload: LoadedFarmSuccessPayload) => {
        return state
            .set('years', payload.years)
            .set('currentYear', getDefaultYear(payload.farm.uuid, payload.years));
    },
});

export const selectCurrentYear = createSelector(
    (state: AppState) => state.yearsState.currentYear,
    (year) => year ?? new Date().getFullYear()
);

export const selectPreviousYear = createSelector(selectCurrentYear, dec);

export const selectPreviousYearHasSubFields = createSelector(
    selectPreviousYear,
    (state: AppState) => state.fieldsState.fields ?? List(),
    (previousYear, fields) => fields.filter((field) => field.year === previousYear).size > 0
);

export const selectPreviousYearHasAnyUsages = createSelector(
    selectPreviousYear,
    (state: AppState) => state.fieldsState.fields ?? List(),
    (previousYear, fields) =>
        fields.filter((field) => field.yearFieldUsages.get(previousYear.toString()) !== undefined)
            .size > 0
);

export const selectPreviousYearHasWorkedArea = createSelector(
    selectPreviousYear,
    (state: AppState) => state.fieldsState.fields ?? List(),
    (year, fields) =>
        fields.filter((field) => field.yearWorkedArea.get(year.toString()) !== undefined).size > 0
);

/**
 * This function determines whether or not to it is possible to copy data from the previous year.
 * Currently this is only used to copy field usages and/or sub fields.
 */
export const canCopyYearData = (state: AppState) => {
    const farm = selectCurrentFarm(state);
    const year = selectCurrentYear(state);
    const previousYear = selectPreviousYear(state);
    const notes = state.notesState.notes;
    const noteYearsLoaded = state.notesState.noteYearsLoaded;
    const operations = state.operationsState.operations;
    const fields = state.fieldsState.fields;

    if (
        !farm.blocked &&
        !farm.plan.blocked &&
        notes !== null &&
        noteYearsLoaded.includes(year) &&
        operations !== null &&
        fields !== null
    ) {
        const hasNoNotes =
            [...notes].map(([_, note]) => note.year).filter((noteYear) => noteYear === year)
                .length === 0;
        const hasNoOps =
            operations.map((fullOp) => fullOp.summary.year).filter((opYear) => opYear === year)
                .size === 0;
        const hasNoUsages =
            fields.filter((field) => field.yearFieldUsages.get(year.toString())).size === 0;
        const hasNoWorkedAreas =
            fields.filter((field) => field.yearWorkedArea.get(year.toString()) !== undefined)
                .size === 0;

        const previousYearHasSubFields =
            fields.filter((field) => field.year === previousYear).size > 0;
        const previousYearHasUsages =
            fields.filter((field) => field.yearFieldUsages.get(previousYear.toString())).size > 0;
        const previousYearHasWorkedAreas =
            fields.filter(
                (field) => field.yearWorkedArea.get(previousYear.toString()) !== undefined
            ).size > 0;

        const previousYearHasSubFieldsUsagesOrWorkedAreas =
            previousYearHasSubFields || previousYearHasUsages || previousYearHasWorkedAreas;

        const currentYearHasSubFields = fields.filter((field) => field.year === year).size > 0;

        return (
            hasNoNotes &&
            hasNoOps &&
            hasNoUsages &&
            hasNoWorkedAreas &&
            !currentYearHasSubFields &&
            previousYearHasSubFieldsUsagesOrWorkedAreas
        );
    }
    return false;
};
