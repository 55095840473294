import { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import type { Farm } from '@fieldmargin/webapp-farms';
import Button from '@fieldmargin/webapp-styling/components/button';
import icon from 'components/icons/icon';
import { setMapIntegrationVisibility } from 'farm-editing/farm-editing-state';
import { selectCurrentFarm } from 'farms/farms-state';
import type { TFunction } from 'i18next';
import type { List, Set } from 'immutable';
import type FarmIntegration from 'integrations/FarmIntegration';
import type { MapGroupsByIntegration } from 'maps/farm/maps-state';
import { selectSortedMapGroupsByIntegration } from 'maps/farm/maps-state';
import MapsListVegetation from 'maps/vegetation/sidebar/MapsListVegetation';
import { bindActionCreators } from 'redux';
import EmptyState from 'sidebar/modules/EmptyState';
import SidebarTitle from 'sidebar/modules/SidebarTitle';
import type { AppState } from 'system/store';
import { changeModal } from 'system/ui-state';

import MapsListIntegration from './MapsListIntegration';

const MAP_UPLOADER_UUID = '1bab41dc-e188-4e5b-b6fb-b438fcdcf03f';

interface MapsListProps {
    // From redux
    farm: Farm;
    mapGroupsByIntegration?: MapGroupsByIntegration;
    farmIntegrations: List<FarmIntegration> | null;

    hiddenMapIntegrations: Set<string>;
    setMapIntegrationVisibility: (payload: {
        farmIntegrationUuid: string;
        visible: boolean;
    }) => void;
    changeModal: typeof changeModal;

    // From i18next
    t: TFunction;
}

class MapsList extends PureComponent<MapsListProps> {
    constructor(props: MapsListProps) {
        super(props);
    }

    render() {
        const { mapGroupsByIntegration, changeModal, t } = this.props;

        if (!mapGroupsByIntegration) {
            return null;
        }

        return (
            <div className="MapsList scrollable">
                <div className="non-scrolling">
                    <SidebarTitle title="Maps">
                        <Button
                            onClick={() => {
                                changeModal({
                                    name: 'connections',
                                    props: {
                                        initialSection: 'maps',
                                    },
                                });
                            }}
                            className="flex items-center"
                        >
                            {icon('add', 'white', 'mr-1')} {t('import')}
                        </Button>
                    </SidebarTitle>
                </div>
                <div className="scrolling">
                    <div>
                        {/*
                            // @ts-ignore - can't get redux and onboarding props to work */}
                        <MapsListVegetation />
                        {this._renderContent()}
                        {this._renderEmptyMapUploader()}
                    </div>
                </div>
            </div>
        );
    }

    _renderContent() {
        const {
            farmIntegrations,
            mapGroupsByIntegration,
            hiddenMapIntegrations,
            setMapIntegrationVisibility,
        } = this.props;

        if (
            !farmIntegrations ||
            !mapGroupsByIntegration ||
            Object.keys(mapGroupsByIntegration).length === 0
        ) {
            return null;
        }

        const items = Object.entries(mapGroupsByIntegration).map(
            ([farmIntegrationUuid, mapGroups]) => {
                const farmIntegration = farmIntegrations.find(
                    (fi) => fi.uuid === farmIntegrationUuid
                );
                if (!farmIntegration) {
                    return null;
                }

                return (
                    <MapsListIntegration
                        key={farmIntegrationUuid}
                        farmIntegration={farmIntegration}
                        mapGroups={mapGroups}
                        visible={!hiddenMapIntegrations.has(farmIntegrationUuid)}
                        setVisible={(visible) => {
                            setMapIntegrationVisibility({ farmIntegrationUuid, visible });
                        }}
                    />
                );
            }
        );

        return items;
    }

    _renderEmptyMapUploader() {
        const { farmIntegrations, mapGroupsByIntegration, changeModal, t } = this.props;

        if (!farmIntegrations || !mapGroupsByIntegration) {
            return null;
        }

        const farmIntegration = farmIntegrations.find(
            (fi) => fi.integrationUuid === MAP_UPLOADER_UUID
        );
        if (farmIntegration) {
            // Have integration, skip this
            return null;
        }

        return (
            <div>
                <div className="flex items-center bg-gray-100 p-5 separator-bottom">
                    <img
                        className="w-12 h-12 mr-5"
                        src="https://integration-assets.fieldmargin.com/assets/1bab41dc-e188-4e5b-b6fb-b438fcdcf03f/map_uploader_256.png"
                        alt="Map uploader logo"
                    />
                    <h4 className="mb-0 text-lg">{t('plan_info_title_map_uploader')}</h4>
                </div>
                <EmptyState header="Upload and store your maps">
                    <p>{t('map_list_map_uploader_empty_desc')}</p>
                    <ul>
                        <li>{t('map_list_map_uploader_empty_item1')}</li>
                        <li>{t('map_list_map_uploader_empty_item2')}</li>
                        <li>{t('map_list_map_uploader_empty_item3')}</li>
                        <li>{t('map_list_map_uploader_empty_item4')}</li>
                        <li>{t('map_list_map_uploader_empty_item5')}</li>
                    </ul>
                    <Button
                        onClick={() => {
                            changeModal({
                                name: 'connections',
                                props: {
                                    initialSection: 'maps',
                                },
                            });
                        }}
                    >
                        {t('import_maps')}
                    </Button>
                </EmptyState>
            </div>
        );
    }
}

export default connect(
    (state: AppState) => {
        const { farmEditingState } = state;
        return {
            farm: selectCurrentFarm(state),
            mapGroupsByIntegration: selectSortedMapGroupsByIntegration(state),
            farmIntegrations: state.integrationsState.farmIntegrations,
            hiddenMapIntegrations: farmEditingState.layerVisibility.hiddenMapIntegrations,
        };
    },
    (dispatch) =>
        bindActionCreators(
            {
                changeModal,
                setMapIntegrationVisibility,
            },
            dispatch
        )
)(withTranslation()(MapsList));
