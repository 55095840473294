import { Outlet, type RouteObject } from 'react-router-dom';
import { RPAImportContainer } from '@notes-web/fieldmargin';
import BoundaryUploaderContainer from 'boundary-uploader/BoundaryUploaderContainer';
import AutoBoundaryContainer from 'fields/sidebar/auto-boundary/AutoBoundaryContainer';
import FieldDeletion from 'fields/sidebar/delete/FieldDeletion';
import EditableFieldDetails from 'fields/sidebar/details/EditableFieldDetails';
import FieldHistory from 'fields/sidebar/details/history/FieldHistory';
import { FieldReporting } from 'fields/sidebar/details/reporting/FieldReporting';
import FieldTodoItems from 'fields/sidebar/details/todo/FieldTodoItems';
import BulkFieldEdit from 'fields/sidebar/edit/BulkFieldEdit';
import FieldList from 'fields/sidebar/FieldList';
import FieldNew from 'fields/sidebar/new/FieldNew';
import BulkUsageContainer from 'fields/sidebar/usage/BulkUsageContainer';
import SubFieldsContainer from 'fields/sub-fields/sidebar/SubFieldsContainer';
import ErrorMessage from 'view/ErrorMessage';

import { APP_ROUTES } from '../types';

export const fieldRoutes: RouteObject = {
    path: APP_ROUTES.Fields.Root,

    children: [
        {
            index: true,
            element: <FieldList />,
            errorElement: (
                <ErrorMessage className="p-5">
                    Something went wrong when viewing fields, please refresh the page.
                </ErrorMessage>
            ),
        },
        {
            path: APP_ROUTES.Fields.New,
            element: <FieldNew />,
            errorElement: (
                <ErrorMessage className="p-5">
                    Something went wrong when creating a field, please refresh the page.
                </ErrorMessage>
            ),
        },
        {
            path: APP_ROUTES.Fields.AutoBoundary,
            element: <AutoBoundaryContainer />,
            errorElement: (
                <ErrorMessage className="p-5">
                    Something went wrong when detecting boundaries, please refresh the page.
                </ErrorMessage>
            ),
        },
        {
            path: APP_ROUTES.Fields.Usage,
            element: <BulkUsageContainer />,
            errorElement: (
                <ErrorMessage className="p-5">
                    Something went wrong when setting usages, please refresh the page.
                </ErrorMessage>
            ),
        },
        {
            path: APP_ROUTES.Fields.BoundaryUploader,
            element: <BoundaryUploaderContainer />,
            errorElement: (
                <ErrorMessage className="p-5">
                    Something went wrong when importing boundaries, please refresh the page.
                </ErrorMessage>
            ),
        },
        {
            path: APP_ROUTES.Fields.RPA,
            element: <RPAImportContainer />,
            errorElement: (
                <ErrorMessage className="p-5">
                    Something went wrong when importing boundaries, please refresh the page.
                </ErrorMessage>
            ),
        },
        {
            path: APP_ROUTES.Fields.Edit,
            element: <BulkFieldEdit />,
            errorElement: (
                <ErrorMessage className="p-5">
                    Something went wrong when editing fields, please refresh the page.
                </ErrorMessage>
            ),
        },

        {
            path: APP_ROUTES.Fields.Field,
            element: <Outlet />,
            errorElement: (
                <ErrorMessage className="p-5">
                    Something went wrong when viewing a field, please refresh the page.
                </ErrorMessage>
            ),

            children: [
                {
                    path: '',

                    element: <EditableFieldDetails />,

                    children: [
                        {
                            index: true,
                            element: <FieldTodoItems />,
                        },
                        {
                            path: APP_ROUTES.Fields.History,
                            element: <FieldHistory />,
                        },

                        {
                            path: APP_ROUTES.Fields.Reports,
                            element: <FieldReporting />,
                        },
                    ],
                },

                {
                    path: APP_ROUTES.Fields.SubFields,
                    element: <SubFieldsContainer />,
                    errorElement: (
                        <ErrorMessage className="p-5">
                            Something went wrong when editing sub-fields, please refresh the page.
                        </ErrorMessage>
                    ),
                },

                {
                    path: APP_ROUTES.Fields.Delete,
                    element: <FieldDeletion />,
                    errorElement: (
                        <ErrorMessage className="p-5">
                            Something went wrong when deleting a field, please refresh the page.
                        </ErrorMessage>
                    ),
                },
            ],
        },
    ],
};
