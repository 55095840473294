import { GeoFeatureCollection } from '@fieldmargin/webapp-geo';
import { Segment } from '@fieldmargin/webapp-reporting';
import { AttachmentType, toAttachmentItem } from 'farm-editing/attachments';
import type { FeatureCollection, Geometry } from 'geojson';
import { List, Record, Set } from 'immutable';
import { defaultToEmptyArray, defaultToZero, notNil } from 'lib/fp-helpers';
import {
    blankFeatureCollection,
    createFeatureCollectionWithIds,
    geoJsonFeatureToGeoFeature,
} from 'lib/geo/geometry';
import type { ActivityType, LastActivity, LastActivityDTO } from 'lib/LastActivity';
import { deserializeLastActivity } from 'lib/LastActivity';
import { defaultTo, omit } from 'ramda';

class Note extends Record({
    uuid: '',
    createdDate: new Date(),
    userCreatedDate: null as Date | null,
    createdByUserId: -1,
    lastModifiedDate: new Date(),
    lastModifiedByUserId: -1,
    lastActivity: null as LastActivity | null,
    version: -1,
    farmUuid: '',
    read: false,
    archived: false,
    taggedUserIds: <number[]>[],
    year: -1,

    // Task related fields:
    task: false,
    dueDate: null as Date | null,
    completedDate: null as Date | null,
    completedByUserId: null as number | null,

    // New properties
    name: '',
    description: '',
    geoJson: {
        type: 'FeatureCollection',
        features: [],
    } as FeatureCollection<Geometry>,

    // Attachments
    fieldUuids: <string[]>[],
    featureUuids: <string[]>[],
    herdUuids: <string[]>[],
}) {
    // The geojson on the note is raw GeoJSON but we need to use the FieldMargin types for
    // the map.
    getGeoFeatureCollection(): GeoFeatureCollection {
        return GeoFeatureCollection({
            features: List(this.geoJson.features.map(geoJsonFeatureToGeoFeature)),
        });
    }
}

/**
 * Object returned from server when fetching a note or notes.
 */
export interface NoteDTO {
    uuid: string;
    name: string;
    version: number;
    year: number;

    active: boolean;
    archived: boolean;
    description: string; // WHAT IS THIS?
    farmUuid: string;
    lastActivity: null | LastActivityDTO;
    read: boolean;

    // Task related fields:
    task?: boolean;
    completedByUserId?: null | number;
    completedDate?: null | number;
    dueDate?: null | number;

    featureUuids: string[];
    fieldUuids: string[] | null;
    geoJson: FeatureCollection<Geometry> | null;
    herdUuids: string[] | null;
    userIds: number[] | null;

    createdByUserId: number;
    createdDate: number;
    userCreatedTime: number | null;
    lastModifiedByUserId: number;
    lastModifiedDate: number;
}

/**
 * Object used sent to the server when updating a note.
 */
export interface WriteNoteDTO {
    name: string;
    description?: string;
    geoJson: FeatureCollection<Geometry>;
    userCreatedTimestamp?: number;
}

/**
 * Object used sent to the server when creating a note.
 * Includes the properties of WriteNoteDTO.
 */
export interface CreateNoteDTO extends WriteNoteDTO {
    fieldUuids: string[];
    featureUuids: string[];
    herdUuids: string[];
    userIds: number[];
}

/**
 * Object sent to server when updating a task
 */
export interface WriteTaskDTO {
    setDueTimestampNull: boolean;
    setCompletedNull: boolean;
    completedByUserId: number | null;
    completedTimestamp: number | null;
    dueTimestamp: number | null;
}

export const deserializeNote = (dto: NoteDTO) =>
    new Note({
        ...dto,
        lastActivity: dto.lastActivity !== null ? deserializeLastActivity(dto.lastActivity) : null,
        geoJson:
            dto.geoJson !== null
                ? createFeatureCollectionWithIds(dto.geoJson)
                : blankFeatureCollection(),
        createdDate: new Date(dto.createdDate),
        userCreatedDate: dto.userCreatedTime ? new Date(dto.userCreatedTime) : null,
        lastModifiedDate: new Date(dto.lastModifiedDate),
        fieldUuids: defaultToEmptyArray(dto.fieldUuids),
        featureUuids: defaultToEmptyArray(dto.featureUuids),
        herdUuids: defaultToEmptyArray(dto.herdUuids),
        taggedUserIds: defaultToEmptyArray(dto.userIds),
        task: defaultTo(false, dto.task),
        dueDate: notNil(dto.dueDate) ? new Date(dto.dueDate!) : null,
        completedDate: notNil(dto.completedDate) ? new Date(dto.completedDate!) : null,
        completedByUserId: dto.completedByUserId,
    });

export const convertWriteNoteDTOToNote = (
    uuid: string,
    farmUuid: string,
    year: number,
    dto: CreateNoteDTO
): Note =>
    new Note({
        uuid,
        farmUuid,
        name: dto.name,
        description: dto.description,
        geoJson: createFeatureCollectionWithIds(dto.geoJson),
        fieldUuids: dto.fieldUuids,
        herdUuids: dto.herdUuids,
        taggedUserIds: dto.userIds,
        year,
    });

export const mergeNoteAndWriteProps = (
    note: Note,
    update: Partial<WriteNoteDTO>,
    newYear?: number
) => {
    let updated = note.merge(omit(['geoJson'], update));
    if (notNil(update.geoJson)) {
        updated = updated
            // Use non-null assertion on geoJson because we check for notNil above
            .set('geoJson', createFeatureCollectionWithIds(update.geoJson!));
    }
    if (notNil(newYear)) {
        updated = updated.set('year', newYear!);
    }
    if (notNil(update.userCreatedTimestamp)) {
        updated = updated.set('userCreatedDate', new Date(update.userCreatedTimestamp!));
    }
    return updated;
};

/**
 * Merges data from updating a task into the given note.
 * This will also set the last activity if the note has been completed or uncompleted.
 */
export const mergeNoteAndWriteTaskProps = (
    note: Note,
    update: Partial<WriteTaskDTO>,
    userId?: number
) => {
    let updated = note.merge(omit(['completedTimestamp', 'dueTimestamp'], update));
    if (update.completedTimestamp !== undefined && update.completedTimestamp !== null) {
        updated = updated.set('completedDate', new Date(update.completedTimestamp));

        // Set the last activity to complete if the note was not complete before
        if (note.completedDate === null) {
            // Completed by user Id should be set when completing, but be safe just in case.
            updated = trackNoteLastActivity(
                updated,
                'completed',
                defaultToZero(update.completedByUserId)
            );
        }
    }
    if (update.setCompletedNull === true) {
        updated = updated.set('completedDate', null).set('completedByUserId', null);
        updated = trackNoteLastActivity(updated, 'todo', defaultToZero(userId));
    }
    if (update.dueTimestamp !== undefined && update.dueTimestamp !== null) {
        updated = updated.set('dueDate', new Date(update.dueTimestamp));
    }
    if (update.setDueTimestampNull === true) {
        updated = updated.set('dueDate', null);
    }
    return updated;
};

export const trackNoteChange = (msg: string) => (note: Note) => {
    const type = note.task ? 'Task' : 'Note';
    Segment.track(`${type} ${msg}`, {
        noteUuid: note.uuid,
    });
    return note;
};

/**
 * Sets the latest activity on the note based on the given type.
 * The date for the last activity will be now.
 */
export const trackNoteLastActivity = (
    note: Note,
    type: ActivityType,
    userId: number,
    metadata?: string
) =>
    note.set('lastActivity', {
        type,
        date: new Date(),
        userId,
        metadata,
    });

const toFieldAttachment = toAttachmentItem(AttachmentType.FIELD);
const toFeatureAttachment = toAttachmentItem(AttachmentType.FEATURE);
export const getNoteAttachmentItems = (note: Note) =>
    Set(note.fieldUuids.map(toFieldAttachment).concat(note.featureUuids.map(toFeatureAttachment)));

export default Note;
