import { useState } from 'react';
import { Controller, FormProvider, useFieldArray, useForm, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import type { Farm } from '@fieldmargin/webapp-farms';
import { FarmUserRole, inviteFarmUser } from '@fieldmargin/webapp-farms';
import type { PendingFarmInvite } from '@fieldmargin/webapp-farms/dist/PendingFarmInvite';
import { Segment } from '@fieldmargin/webapp-reporting';
import { usePromise, useToggle } from '@fieldmargin/webapp-state';
import SubmitButton from '@fieldmargin/webapp-styling/components/button/SubmitButton';
import Promise from 'bluebird';
import { selectCurrentFarm } from 'farms/farms-state';
import { bindActionCreators } from 'redux';
import type { AppState } from 'system/store';
import {
    addPendingInvites,
    selectMaxInviteCount,
    selectShouldBlockInviting,
} from 'team/farm-users-state';
import FarmUserRoleSelect from 'team/settings/FarmUserRoleSelect';
import { finishTutorial } from 'tutorials/tutorials-state';
import { TutorialTypes } from 'tutorials/TutorialTypes';
import ErrorMessage from 'view/ErrorMessage';
import TextInputField from 'view/form/hook/TextInputField';
import { validateEmail } from 'view/form/validations';

import TeamInviteBlockedModal from './TeamInviteBlockedModal';

interface InviteFormProps {
    // From parent
    showToast: (message: string, level?: string) => void;
    postSave?: VoidFunction;
    openDirection?: 'left' | 'right';
    // From redux
    farm: Farm;
    addPendingInvites: typeof addPendingInvites;
    finishTutorial: typeof finishTutorial;
    blockInviting: boolean;
    maxInputs: number;
}

interface FormValues {
    invites: {
        email: string;
        role: FarmUserRole;
    }[];
}

const InviteForm = ({
    farm,
    showToast,
    postSave,
    addPendingInvites,
    finishTutorial,
    blockInviting,
    maxInputs,
    openDirection = 'left',
}: InviteFormProps) => {
    const { t } = useTranslation();
    const [showBlockModal, toggleBlockModal] = useToggle(false);

    const methods = useForm<FormValues>({
        mode: 'onChange',
        defaultValues: {
            invites: [{ email: '', role: FarmUserRole.MEMBER }],
        },
    });
    const { fields, append } = useFieldArray({ control: methods.control, name: 'invites' });

    const { pending, error, setPromise } = usePromise<PendingFarmInvite[]>((invites) => {
        methods.reset();
        showToast(t('info_invitation_send'));
        addPendingInvites(invites);
        Segment.track('Invite sent', {});
        postSave && postSave();
    });

    const handleSubmit = (values: FormValues) => {
        if (blockInviting) {
            toggleBlockModal();
            return;
        }

        const validInvites = values.invites.filter(({ email }) => email !== '');

        if (validInvites.length === 0) {
            return;
        }

        setPromise(
            Promise.all(
                validInvites.map(({ email, role }) => inviteFarmUser(farm.uuid, email, role))
            )
        );
    };
    return (
        <FormProvider {...methods}>
            {showBlockModal && <TeamInviteBlockedModal onClose={toggleBlockModal} />}
            <form
                className="InviteForm flex flex-col gap-4"
                onSubmit={methods.handleSubmit(handleSubmit)}
            >
                {fields.map((item, i) => {
                    return (
                        <div key={item.id}>
                            <InviteField
                                add={() =>
                                    fields.length < maxInputs &&
                                    append(
                                        { email: '', role: FarmUserRole.MEMBER },
                                        { shouldFocus: false }
                                    )
                                }
                                field={`invites.${i}`}
                                validate={validateEmail}
                                index={i}
                                onFocus={() => {
                                    i === 0 && finishTutorial(TutorialTypes.FARM_SHARE);
                                }}
                                openDirection={openDirection}
                            />
                        </div>
                    );
                })}
                <div className="form-field flex gap-4">
                    <input
                        type="email"
                        className="simple-text-input flex-grow"
                        placeholder={t('invite_email_placeholder')}
                        disabled
                    />
                    <button className="bare-btn focus FarmUserRoleSelectButton w-1/3" disabled>
                        {t('member')} <i className="ion-chevron-down" />
                    </button>
                </div>
                <SubmitButton disabled={pending} className="self-end">
                    {t('send_invites')}
                </SubmitButton>
                {error && (
                    <ErrorMessage className="clear-both mt-4">{t('invite_error')}</ErrorMessage>
                )}
            </form>
        </FormProvider>
    );
};

export default connect(
    (state: AppState) => ({
        farm: selectCurrentFarm(state),
        blockInviting: selectShouldBlockInviting(state),
        maxInputs: selectMaxInviteCount(state),
    }),
    (dispatch) => bindActionCreators({ addPendingInvites, finishTutorial }, dispatch)
)(InviteForm);

const InviteField = ({
    add,
    field,
    validate,
    index,
    onFocus,
    openDirection,
}: {
    add: VoidFunction;
    field: string;
    validate: any;
    index: number;
    onFocus: VoidFunction;
    openDirection: 'left' | 'right';
}) => {
    const { t } = useTranslation();
    const { control } = useFormContext();
    const [added, setAdded] = useState(false);
    const handleChange = () => {
        if (!added) {
            add();
            setAdded(true);
        }
    };
    return (
        <div className="InviteField flex gap-4">
            <TextInputField
                label={index === 0 ? 'Email' : undefined}
                labelClassName="font-bold"
                field={`${field}.email`}
                type="email"
                validate={validate}
                placeholder={t('invite_email_placeholder')}
                onChange={handleChange}
                onFocus={onFocus}
                inputClassName="w-full"
                className="flex-grow"
            />
            <div className="w-1/3">
                {index === 0 && <label className="font-bold">{t('role')}</label>}
                <Controller
                    control={control}
                    name={`${field}.role`}
                    render={({ field: { value, onChange } }) => (
                        <FarmUserRoleSelect
                            value={value}
                            onChange={onChange}
                            openDirection={openDirection}
                        />
                    )}
                />
            </div>
        </div>
    );
};
