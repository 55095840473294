import type { FarmUser } from '@fieldmargin/webapp-farms';
import { createFarm } from '@fieldmargin/webapp-farms';
import type { GeoPosition } from '@fieldmargin/webapp-geo';

export const createFarmRequest = (
    farmName: string,
    farmLocation: GeoPosition,
    owner: FarmUser | null
) =>
    createFarm(farmName, { type: 'Point', coordinates: [farmLocation.x, farmLocation.y] }).then(
        (farm) =>
            owner !== null
                ? {
                      ...farm,
                      owners: [owner],
                  }
                : farm
    );
