import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import type { Farm } from '@fieldmargin/webapp-farms';
import { Segment } from '@fieldmargin/webapp-reporting';
import { usePromise } from '@fieldmargin/webapp-state';
import Button from '@fieldmargin/webapp-styling/components/button';
import SubmitButton from '@fieldmargin/webapp-styling/components/button/SubmitButton';
import { selectCurrentFarm } from 'farms/farms-state';
import { useOperationDetailsActions } from 'hooks/actions';
import { Form, Select } from 'informed';
import Output, { OutputUnit } from 'outputs/Output';
import OutputNameField from 'outputs/OutputNameField';
import { saveOutputApi } from 'outputs/outputs-api';
import { setOutput } from 'outputs/outputs-state';
import { bindActionCreators } from 'redux';
import SidebarHeader from 'sidebar/modules/header/SidebarHeader';
import type { AppState } from 'system/store';
import type { SingleParamVoidFunction } from 'system/types';
import ErrorMessage from 'view/ErrorMessage';
import { required } from 'view/form/validations';

interface NewOutputFormProps {
    // From parents
    onDone: SingleParamVoidFunction<Output>;

    // From redux
    farm: Farm;
    setOutput: typeof setOutput;
}

interface FormValues {
    name: string;
    unit: OutputUnit;
}

const NewOutputForm = ({ onDone, farm, setOutput }: NewOutputFormProps) => {
    const { t } = useTranslation();
    const { handleToggleAddingOutput: handleCancel } = useOperationDetailsActions();
    const { pending, error, setPromise } = usePromise<Output>((output) => {
        setOutput(output);
        Segment.track('Create output', { farmUuid: farm.uuid });
        onDone(output);
    });
    const handleSubmit = (values: FormValues) => {
        const output = new Output({
            farmUuid: farm.uuid,
            name: values.name,
            unit: values.unit,
        });
        setPromise(saveOutputApi(farm.uuid, output));
    };

    const validateRequired = required(t('register_required'));

    return (
        <Form<FormValues> className="NewOutputForm scrollable" onSubmit={handleSubmit}>
            <SidebarHeader className="px-5">
                <Button onClick={handleCancel} variant="danger-outline" small>
                    {t('cancel')}
                </Button>
                <SubmitButton className="w-32" disabled={pending} small>
                    {t('save')}
                </SubmitButton>
            </SidebarHeader>
            <div className="p-5">
                <OutputNameField
                    field="name"
                    label={t('name')}
                    validate={validateRequired}
                    className="mb-5"
                    labelClassName="font-bold text-base"
                />
                <div>
                    <label className="inline-block mr-5 font-bold" htmlFor="unit">
                        {t('unit')}
                    </label>
                    <Select field="unit" id="unit">
                        {Object.keys(OutputUnit).map((outputUnit) => (
                            <option key={outputUnit} value={outputUnit}>
                                {t(`unit_type_${outputUnit.toLowerCase()}`)}
                            </option>
                        ))}
                    </Select>
                </div>
                {error && <ErrorMessage className="mt-5">{t('something_went_wrong')}</ErrorMessage>}
            </div>
        </Form>
    );
};

export default connect(
    (state: AppState) => ({
        farm: selectCurrentFarm(state),
    }),
    (dispatch) => bindActionCreators({ setOutput }, dispatch)
)(NewOutputForm);
