import type { Farm } from '@fieldmargin/webapp-farms';
import { findFarm } from '@fieldmargin/webapp-farms';
import { Segment } from '@fieldmargin/webapp-reporting';
import type FarmInvite from 'farms/invites/FarmInvite';
import { refreshFirebaseToken } from 'lib/firebase/firebase-connection';
import { acceptInviteApi, declineInviteApi } from 'users/users-api';

type addFarmType = (farm: Farm) => void;
type removeInviteType = (invite: FarmInvite) => void;

export const acceptInvite = async (
    invite: FarmInvite,
    addFarm: addFarmType,
    removeInvite: removeInviteType
) => {
    try {
        await acceptInviteApi(invite);
    } catch (e) {
        if (e.response.status === 400 && e.response.data.error === 'over-user-limit') {
            return 'over-user-limit';
        }
    }
    await refreshFirebaseToken();
    // The farm object in the response from the invitation does not contain the plan so we
    // need to reload the farm in order to get that.
    const farm = await findFarm(invite.farm.uuid);
    addFarm(farm);
    removeInvite(invite);
    Segment.track('Invite accepted', {
        farmUuid: farm.uuid,
    });
    return farm;
};

export const declineInvite = async (invite: FarmInvite, removeInvite: removeInviteType) => {
    await declineInviteApi(invite);
    removeInvite(invite);
    Segment.track('Invite declined', {
        farmUuid: invite.farm.uuid,
    });
};
