import type { Farm, FarmUser } from '@fieldmargin/webapp-farms';
import { Segment } from '@fieldmargin/webapp-reporting';
import type Field from 'fields/Field';
import { List, Set } from 'immutable';
import { areaAsSqm } from 'lib/geo/maths';
import { listToMap } from 'lib/immutil';
import type { MeasurementUnit } from 'lib/MeasurementUnit';

import { calculateYieldRateHa } from './sidebar/details/yields/yield-utils';
import type { OperationNewFormValues } from './sidebar/new/OperationNew';
import type { OperationChangeDTO } from './FullOperation';
import type FullOperation from './FullOperation';
import { serializeFullOperationFields } from './FullOperation';
import Operation, { OperationType, serializeOperation } from './Operation';
import type { OperationField } from './OperationField';
import { hasOperationFieldChanged } from './OperationField';
import { saveOperationApi } from './operations-api';
import type Recording from './Recording';
import { hasRecordingChanged, serializeRecording } from './Recording';

/**
 * Save a new operation from form field values.
 * Returns a FullOperation object.
 */
export const saveNewOperation = async (
    farm: Farm,
    myFarmUser: FarmUser,
    editingFieldUuids: Set<string>,
    fields: List<Field>,
    editingOperationRecordings: Set<Recording>,
    values: OperationNewFormValues,
    areaMeasurementUnits: MeasurementUnit,
    outputUuid?: string
) => {
    const yearNumber = parseInt(values.year, 10);
    const operation = Operation({
        farmUuid: farm.uuid,
        year: yearNumber,
        name: values.name,
        createdByUserId: myFarmUser.id,
        type: values.operationType ? OperationType[values.operationType] : OperationType.OTHER,
        dueDate: values.dueDate,
        taggedUserIds: values.taggedUsers.map((farmUser) => farmUser.id),
        outputUuid: outputUuid ? outputUuid : null,
    });
    const operationFields = editingFieldUuids.reduce((operationFields, fieldUuid) => {
        const field = fields.find((field) => field.uuid === fieldUuid);
        if (!field) {
            return operationFields;
        }

        const workArea = values.fields[fieldUuid].workArea;
        const yieldTotal = values.fields[fieldUuid].yieldTotal;

        return operationFields.add({
            fieldUuid,
            areaSqm: areaAsSqm(workArea, areaMeasurementUnits),
            yieldRateHa:
                yieldTotal !== undefined
                    ? calculateYieldRateHa(workArea, yieldTotal, areaMeasurementUnits)
                    : undefined,
            yieldTotal,
        });
    }, Set<OperationField>());

    const changeDto: OperationChangeDTO = {
        operation: serializeOperation(operation),
        fields: serializeFullOperationFields(operationFields),
        tags: { userIds: values.taggedUsers.map((farmUser) => farmUser.id).toArray() },
    };

    // We have to save twice because trying to save recordings without an operation UUID results
    // in a server error.
    const savedOperation = await saveOperationApi(farm.uuid, yearNumber, changeDto);

    // For harvest operations we don't need to save recordings, we also don't need to save
    // if there are no recordings set.
    if (operation.type === OperationType.HARVEST || editingOperationRecordings.size === 0) {
        return savedOperation;
    }

    // This second save adds the operation UUID to the recordings and saves the operation again
    // with the recordings.
    return await saveOperationApi(farm.uuid, yearNumber, {
        operation: serializeOperation(savedOperation.summary),
        operationRecordingDTOS: editingOperationRecordings
            .map((recording) =>
                serializeRecording(recording.set('operationUuid', savedOperation.uuid))
            )
            .toArray(),
    });
};

/**
 * Adds missing data from the previous full operation to the new full operation.
 * Currently the server doesn't return comments or media as part of the DTO when saving an
 * operation. This function adds them back on so that the app can consider the operation
 * fully loaded.
 */
const addMissingDataPostSave = (
    previousFullOperation: FullOperation,
    newFullOperation: FullOperation
) =>
    newFullOperation
        .set('comments', previousFullOperation.comments)
        .set('media', previousFullOperation.media);

/**
 * Update Operation details only, e.g. title, due date, year
 * Returns a FullOperation object.
 */
export const saveOperation = async (farmUuid: string, fullOperation: FullOperation) =>
    addMissingDataPostSave(
        fullOperation,
        await saveOperationApi(farmUuid, fullOperation.summary.year, {
            operation: serializeOperation(fullOperation.summary),
        })
    );

/**
 * Save a single field against an operation.
 */
export const saveOperationField = async (
    farmUuid: string,
    fullOperation: FullOperation,
    operationField: OperationField
) =>
    addMissingDataPostSave(
        fullOperation,
        await saveOperationApi(farmUuid, fullOperation.summary.year, {
            operation: serializeOperation(fullOperation.summary),
            fields: serializeFullOperationFields(Set.of(operationField)),
        })
    );

/**
 * Generates a diff between the current full operation and the new fields. The result can be used
 * to save the operation in the most efficient way.
 */
export const syncOperationFields = (
    fullOperation: FullOperation,
    operationFields: Set<OperationField>
) => {
    const operationFieldUuids = operationFields.map((opField) => opField.fieldUuid);
    const changedFields = serializeFullOperationFields(
        operationFields.reduce((set, opField) => {
            const existingField = fullOperation.fields?.find(
                ({ fieldUuid }) => opField.fieldUuid === fieldUuid
            );
            return existingField === undefined || hasOperationFieldChanged(existingField, opField)
                ? set.add(opField)
                : set;
        }, Set<OperationField>())
    );
    return {
        fields: changedFields.length === 0 ? undefined : changedFields,
        fieldsDeleted: fullOperation.fields
            ?.filter(({ fieldUuid }) => !operationFieldUuids.contains(fieldUuid))
            .map(({ fieldUuid }) => fieldUuid)
            .toArray(),
    };
};

/**
 * Save operation fields for multiple operations.
 */
export const saveOperationsFields = async (
    farmUuid: string,
    data: List<{ fullOperation: FullOperation; operationFields: Set<OperationField> }>
) => {
    const savedOperations = await Promise.all(
        data.map(({ fullOperation, operationFields }) =>
            saveOperationFields(farmUuid, fullOperation, operationFields)
        )
    );
    return listToMap<string, FullOperation>(List(savedOperations), ({ uuid }) => uuid);
};

/**
 * Saves the given fields against the operation.
 */
export const saveOperationFields = async (
    farmUuid: string,
    fullOperation: FullOperation,
    operationFields: Set<OperationField>
) => {
    const savedOperation = addMissingDataPostSave(
        fullOperation,
        await saveOperationApi(farmUuid, fullOperation.summary.year, {
            ...syncOperationFields(fullOperation, operationFields),
            operation: serializeOperation(fullOperation.summary),
        })
    );
    Segment.track('Field Operation updated', { operationUuid: fullOperation.uuid });
    return savedOperation;
};

/**
 * Generates a diff between the current full operation and the new recordings. The result can be used
 * to save the operation in the most efficient way.
 */
export const syncOperationRecordings = (
    fullOperation: FullOperation,
    recordings: Set<Recording>
) => {
    const recordingUuids = recordings.map((recording) => recording.uuid);
    const changedRecordings = recordings
        .reduce((set, recording) => {
            const existingRecording = fullOperation.recordings?.find(
                ({ uuid }) => uuid === recording.uuid
            );
            return existingRecording === undefined ||
                hasRecordingChanged(existingRecording, recording)
                ? set.add(recording)
                : set;
        }, Set<Recording>())
        .map(serializeRecording)
        .toArray();
    return {
        operationRecordingDTOS: changedRecordings.length === 0 ? undefined : changedRecordings,
        operationRecordingDeleted: fullOperation.recordings
            ?.filter(({ uuid }) => !recordingUuids.contains(uuid))
            .map(({ uuid }) => uuid)
            .toArray(),
    };
};

/**
 * Update operation recordings only.
 */
export const saveOperationRecordings = async (
    farmUuid: string,
    fullOperation: FullOperation,
    newRecordings: Set<Recording>
) => {
    return addMissingDataPostSave(
        fullOperation,
        await saveOperationApi(farmUuid, fullOperation.summary.year, {
            operation: serializeOperation(fullOperation.summary),
            ...syncOperationRecordings(fullOperation, newRecordings),
        })
    );
};

/**
 * Save tagged users against an operation. Any users not in the newTaggedUsers set will
 * be removed.
 */
export const saveOperationTaggedUsers = async (
    farmUuid: string,
    fullOperation: FullOperation,
    newTaggedUsers: Set<number>
) => {
    const deletedTags = fullOperation.summary.taggedUserIds.filter(
        (userId) => !newTaggedUsers.contains(userId)
    );
    return addMissingDataPostSave(
        fullOperation,
        await saveOperationApi(farmUuid, fullOperation.summary.year, {
            operation: serializeOperation(fullOperation.summary),
            tags: { userIds: newTaggedUsers.toArray() },
            tagsDeleted: deletedTags.toArray(),
        })
    );
};
