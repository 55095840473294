import { useTranslation } from 'react-i18next';
import type { Farm } from '@fieldmargin/webapp-farms';
import { useSegmentMountTracking } from '@fieldmargin/webapp-reporting';
import Button from '@fieldmargin/webapp-styling/components/button';
import { preventDefaultAnd } from 'lib/dom';
import { allTrue } from 'lib/fp-helpers';
import type { CodedItem, SingleParamVoidFunction } from 'system/types';

import type { PesticideCheckFilter } from '../details/recording/pesticide-check-state';

import type { PesticideCheckResponseFilters } from './PesticideCheck';
import PesticideCheckCropFilter from './PesticideCheckCropFilter';
import PesticideCheckCropStageFilter from './PesticideCheckCropStageFilter';
import PesticideCheckPestFilter from './PesticideCheckPestFilter';

interface PesticideCheckPestAndStageProps {
    farm: Farm;
    onBack: VoidFunction;
    onNext: VoidFunction;
    pendingData: boolean;
    filterValues: PesticideCheckFilter;
    filters?: PesticideCheckResponseFilters;
    setPest: SingleParamVoidFunction<string | undefined>;
    setCropStage: SingleParamVoidFunction<string | undefined>;
    setCrop: SingleParamVoidFunction<CodedItem | undefined>;
}

const PesticideCheckPestAndStage = ({
    farm,
    onBack,
    onNext,
    filterValues,
    filters,
    setPest,
    setCropStage,
    setCrop,
    pendingData,
}: PesticideCheckPestAndStageProps) => {
    const { t } = useTranslation();
    useSegmentMountTracking('Pesticide check crop, pest & stage seen', {
        farmUuid: farm.uuid,
    });
    const filtersSelected = allTrue([
        filterValues.crop !== undefined,
        filterValues.pest !== undefined,
        filterValues.cropStage !== undefined,
    ]);
    return (
        <div className="flex flex-col gap-6">
            {pendingData && (
                <p className="mb-0">
                    <em>{t('pesticide_check_crop_stage_loading')}</em>
                </p>
            )}
            <div>
                <label htmlFor="crop">{t('pesticide_check_crop_target')}</label>
                <PesticideCheckCropFilter
                    farm={farm}
                    disabled={pendingData}
                    crops={filters?.crops ?? {}}
                    selected={filterValues.crop}
                    setCrop={setCrop}
                    includeNoValue
                />
            </div>
            <div>
                <label htmlFor="pest">{t('pesticide_check_pest_target')}</label>
                <PesticideCheckPestFilter
                    farm={farm}
                    disabled={pendingData}
                    selected={filterValues.pest}
                    pests={filters?.pests}
                    setPest={setPest}
                    includeNoValue
                />
            </div>
            <div>
                <label htmlFor="cropStage">{t('pesticide_check_crop_stage_target')}</label>
                <PesticideCheckCropStageFilter
                    farm={farm}
                    cropStages={filters?.cropStages}
                    selected={filterValues.cropStage}
                    setCropStage={setCropStage}
                    disabled={pendingData}
                    includeNoValue
                />
            </div>
            <div className="flex justify-center gap-4 mt-2">
                <Button
                    variant="outline"
                    onClick={preventDefaultAnd(onBack)}
                    disabled={pendingData}
                >
                    {t('back')}
                </Button>
                <Button
                    disabled={pendingData || !filtersSelected}
                    onClick={preventDefaultAnd(onNext)}
                >
                    {t('next')}
                </Button>
            </div>
        </div>
    );
};

export default PesticideCheckPestAndStage;
