import { useEffect, useReducer } from 'react';
import { useSelector } from 'react-redux';
import type { Farm } from '@fieldmargin/webapp-farms';
import { usePromise } from '@fieldmargin/webapp-state';
import { getUserLocationApi } from 'farms/create/create-farm-api';
import { createFarmRequest } from 'farms/create/create-farm-save';
import {
    basemapClickReducer,
    featureClickReducer,
    geoLocationLookupReducer,
    searchedPlacesReducer,
} from 'farms/create/create-farm-utils';
import type { CreateFarmFormValues } from 'farms/create/CreateFarmForm';
import CreateFarmState from 'farms/create/CreateFarmState';
import { addFarm } from 'farms/farms-state';
import { refreshFirebaseToken } from 'lib/firebase/firebase-connection';
import { useAppDispatch } from 'system/store';
import { selectUserAsFarmOwner } from 'users/user-state';

const reducer = (state: CreateFarmState, action: { type: string; payload: any }) => {
    switch (action.type) {
        case 'userLocation':
            return state.set('userLocation', action.payload);
        case 'searchedPlaces':
            return searchedPlacesReducer(action.payload, state);
        case 'geoLocationLookup':
            return geoLocationLookupReducer(action.payload, state);
        case 'basemapClick':
            return basemapClickReducer(action.payload, state);
        case 'featureClick':
            return featureClickReducer(action.payload, state);
        case 'setFarmLocationError':
            return state.set('farmLocationFieldError', action.payload);
        default:
            throw new Error(`Unknown create farm state action: ${action.type}`);
    }
};

interface UseCreateFarmProps {
    onSuccess?: (farm: Farm) => void;
}

const useCreateFarm = ({ onSuccess }: UseCreateFarmProps) => {
    const dispatchRedux = useAppDispatch();
    const [state, dispatch] = useReducer(reducer, CreateFarmState({}));

    const userFarmUser = useSelector(selectUserAsFarmOwner);

    const { pending, error, setPromise } = usePromise<Farm>(async (farm) => {
        dispatchRedux(addFarm(farm));
        onSuccess?.(farm);
    });

    const handleSubmit = async ({ name }: CreateFarmFormValues) => {
        if (!state.farmLocation) {
            dispatch({ type: 'setFarmLocationError', payload: true });
        } else if (name) {
            const promise = createFarmRequest(name, state.farmLocation, userFarmUser).then(
                async (newFarm) => {
                    // Refresh the firebase token so that the newly created farm is included in it.
                    await refreshFirebaseToken();
                    return newFarm;
                }
            );

            setPromise(promise);
        }
    };

    useEffect(() => {
        const getUserLocation = async () => {
            try {
                const userLocation = await getUserLocationApi();
                dispatch({ type: 'userLocation', payload: userLocation });
            } catch (_e) {
                // Do nothing if this fails, user will have to find their place themselves.
            }
        };
        getUserLocation();
    }, []);

    return {
        isPending: pending,
        isError: error,
        onCreateFarm: handleSubmit,
        state,
        dispatch,
    };
};

export { useCreateFarm };
