import { useNavigate } from 'react-router-dom';
import type { Farm } from '@fieldmargin/webapp-farms';
import { Segment } from '@fieldmargin/webapp-reporting';
import CreateFarmContainer from 'farms/create/CreateFarmContainer';
import { useOnboarding } from 'onboarding/onboarding-state';

function CreateFarmFieldmargin() {
    const navigate = useNavigate();
    const { startOnboarding } = useOnboarding();

    const onSuccess = (farm: Farm) => {
        Segment.track('Farm created', { farmUuid: farm.uuid });
        startOnboarding();
        navigate(`/farms/${farm.uuid}/notes`);
    };

    return <CreateFarmContainer onSuccess={onSuccess} />;
}

export { CreateFarmFieldmargin };
