import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import type { Farm } from '@fieldmargin/webapp-farms';
import { Segment } from '@fieldmargin/webapp-reporting';
import { clsx } from 'clsx';
import icon from 'components/icons/icon';
import { selectCurrentFarm } from 'farms/farms-state';
import type { AppState } from 'system/store';
import UpgradeLink from 'upgrade/UpgradeLink';

import styles from './LockedTooltip.module.css';

interface LockedTooltipProps {
    farm: Farm;
    className?: string;
}

const LockedTooltip = ({ farm, className }: LockedTooltipProps) => {
    const { t } = useTranslation();
    return (
        <span className={clsx(styles.lockedTooltip, className)}>
            {icon('padlock', 'blue')}
            <div className={styles.lockedInputTooltip}>
                <UpgradeLink
                    onClick={() =>
                        Segment.track('Input locked upgrade clicked', { farm: farm.uuid })
                    }
                >
                    {t('pro_upgrade_title')}.{' '}
                    {t('pro_upgrade_subtitle_1', { sprintf: [farm.plan.inputLimit] })}{' '}
                    {t('pro_upgrade_subtitle_2')}
                </UpgradeLink>
            </div>
        </span>
    );
};

export default connect((state: AppState) => ({
    farm: selectCurrentFarm(state),
}))(LockedTooltip);
