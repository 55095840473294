import { Outlet, type RouteObject } from 'react-router-dom';
import FeatureDeletion from 'features/sidebar/delete/FeatureDeletion';
import EditableFeatureDetails from 'features/sidebar/details/EditableFeatureDetails';
import FeatureHistory from 'features/sidebar/details/FeatureHistory';
import { FeatureTodo } from 'features/sidebar/details/FeatureTodo';
import FeatureList from 'features/sidebar/list/FeatureList';
import FeatureNew from 'features/sidebar/new/FeatureNew';
import ErrorMessage from 'view/ErrorMessage';

import { APP_ROUTES } from '../types';

export const featuresRoutes: RouteObject = {
    path: APP_ROUTES.Features.Root,

    children: [
        {
            index: true,
            element: <FeatureList />,
            errorElement: (
                <ErrorMessage className="p-5">
                    Something went wrong when viewing features, please refresh the page.
                </ErrorMessage>
            ),
        },
        {
            path: APP_ROUTES.Features.New,
            element: <FeatureNew />,
            errorElement: (
                <ErrorMessage className="p-5">
                    Something went wrong when creating a feature, please refresh the page.
                </ErrorMessage>
            ),
        },

        {
            path: APP_ROUTES.Features.Feature,
            element: <Outlet />,
            errorElement: (
                <ErrorMessage className="p-5">
                    Something went wrong when viewing a feature, please refresh the page.
                </ErrorMessage>
            ),
            children: [
                {
                    path: '',
                    element: <EditableFeatureDetails />,
                    children: [
                        {
                            index: true,
                            element: <FeatureTodo />,
                        },
                        {
                            path: APP_ROUTES.Features.History,
                            element: <FeatureHistory />,
                        },
                    ],
                },
                {
                    path: APP_ROUTES.Features.Delete,
                    element: <FeatureDeletion />,
                    errorElement: (
                        <ErrorMessage className="p-5">
                            Something went wrong when deleting a feature, please refresh the page.
                        </ErrorMessage>
                    ),
                },
            ],
        },
    ],
};
