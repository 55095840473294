import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import type { Farm } from '@fieldmargin/webapp-farms';
import type { GeoFeatureCollection } from '@fieldmargin/webapp-geo';
import type { Action } from '@fieldmargin/webapp-state';
import { usePromise } from '@fieldmargin/webapp-state';
import { selectCurrentFarm } from 'farms/farms-state';
import type { WriteFieldProps, WriteSubFieldProps } from 'fields/Field';
import type Field from 'fields/Field';
import {
    mergeFieldAndWriteProps,
    mergeSubFieldAndWriteProps,
    trackFieldChange,
} from 'fields/Field';
import {
    createField,
    deleteField,
    toggleFieldArchived,
    updateField,
    updateSubField,
} from 'fields/fields-api';
import { removeField, setField } from 'fields/fields-state';
import { useCurrentFarm } from 'hooks/useCurrentFarm';
import { dispatchAndReturn } from 'lib/fp-helpers';
import { useAction } from 'lib/hooks';
import { pipe } from 'ramda';
import type { AppState } from 'system/store';
import { useAppDispatch } from 'system/store';
import type { SingleParamVoidFunction } from 'system/types';
import { selectCurrentYear } from 'years/years-state';

interface CreateFieldProps {
    name: string;
    fieldId: string;
    geoJson: GeoFeatureCollection;
    fieldUsageUuid?: string;
    onSave?: SingleParamVoidFunction<Field>;
}

export const useCreateField = () => {
    const dispatch = useAppDispatch();
    const farm = useSelector<AppState, Farm>(selectCurrentFarm);
    const year = useSelector<AppState, number>(selectCurrentYear);

    const { pending, error, setPromise } = usePromise();

    const createFieldFn = ({ name, fieldId, geoJson, fieldUsageUuid, onSave }: CreateFieldProps) =>
        setPromise(
            createField(farm.uuid, {
                name,
                fieldId,
                geoJson,
                yearFieldUsage: { year, fieldUsageUuid },
            })
                .then(trackFieldChange('created'))
                .then(dispatchAndReturn<Field>(dispatch, setField))
                .then(onSave)
        );

    return { isPending: pending, isError: error, onCreateField: createFieldFn };
};

/**
 * This should be used when updating field properties.
 * Use useUpdateSubField when updating sub field properties because this creates the
 * correct events on the server.
 */
export const useUpdateField = () => {
    const dispatch = useAppDispatch();
    const farm = useSelector<AppState, Farm>(selectCurrentFarm);
    const setFieldAction = pipe<[Field], Action<Field>, void>(setField, dispatch);

    return {
        updateField: (field: Field, data: Partial<WriteFieldProps>) =>
            updateField(farm.uuid, field.uuid, data)
                .then((savedField) => mergeFieldAndWriteProps(field, savedField))
                .then(trackFieldChange('updated'))
                .then(setFieldAction),
    };
};

/**
 * This should be used when updating sub field properties.
 * Use useUpdateField when updating field properties because this creates the
 * correct events on the server.
 */
export const useUpdateSubField = (field: Field) => {
    const dispatch = useAppDispatch();
    const { currentFarm } = useCurrentFarm();
    const setFieldAction = pipe<[Field], Action<Field>, void>(setField, dispatch);

    return (data: Partial<WriteSubFieldProps>) =>
        updateSubField(currentFarm.uuid, field.uuid, data)
            .then((savedField) => mergeSubFieldAndWriteProps(field, savedField))
            .then(trackFieldChange('updated'))
            .then(setFieldAction);
};

export const useDeleteField = (field: Field) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { currentFarm } = useCurrentFarm();

    const { pending, error, setPromise } = usePromise<Field>((field) => {
        trackFieldChange('deleted')(field);
        dispatch(removeField(field));
        navigate(`/farms/${currentFarm.uuid}/fields`);
    });

    const onDeleteField = () => setPromise(deleteField(currentFarm.uuid, field));

    return { isPending: pending, isError: error, onDeleteField };
};

export const useDeleteEditField = () => {
    const dispatch = useAppDispatch();
    const { currentFarm } = useCurrentFarm();

    const { pending, error, setPromise } = usePromise();
    const onDeleteEditField = ({ field, onDelete }: { field: Field; onDelete?: () => void }) => {
        dispatch(removeField(field));

        setPromise(deleteField(currentFarm.uuid, field).then(onDelete));
    };

    return { isPending: pending, isError: error, onDeleteEditField };
};

export const useSetFieldArchived = (field: Field) => {
    const setFieldAction = useAction(setField);
    const { currentFarm } = useCurrentFarm();
    const event = !field.archived ? 'archived' : 'unarchived';

    return () =>
        toggleFieldArchived(currentFarm.uuid, field.uuid, !field.archived)
            .then(() => field.set('archived', !field.archived))
            .then(trackFieldChange(event))
            .then(setFieldAction);
};
