import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import type { Farm } from '@fieldmargin/webapp-farms';
import { farmCanBeUpgraded, farmHasNoSub } from '@fieldmargin/webapp-farms';
import { Segment } from '@fieldmargin/webapp-reporting';
import c from 'classnames';
import { selectCurrentFarm } from 'farms/farms-state';
import { useDateFormat } from 'hooks/useDateFormat';
import type { AppState } from 'system/store';
import UpgradeButton from 'upgrade/UpgradeButton';

import './TopNavUpgradePrompt.css';

interface TopNavUpgradePromptProps {
    farm: Farm;
    membershipFinishAt: Date | null;
    className?: string;
}

const TopNavUpgradePrompt = ({ farm, membershipFinishAt, className }: TopNavUpgradePromptProps) => {
    if (!farm || farm.demo) {
        return null;
    }

    if (farm && farm.freeTrial && membershipFinishAt) {
        return (
            <div className="TopNavUpgradePrompt">
                <UpgradeTrialLink farm={farm} finishesAt={membershipFinishAt} />
            </div>
        );
    }

    // Nothing to show if the farm cannot be upgraded
    if (!farmCanBeUpgraded(farm)) {
        return null;
    }

    return (
        <div className={c('TopNavUpgradePrompt', className)}>
            {farmHasNoSub(farm) ? (
                <UpgradeButton
                    variant="yellow"
                    small
                    onClick={() =>
                        Segment.track('Top nav upgrade link clicked', { farmUuid: farm.uuid })
                    }
                />
            ) : (
                <UpgradeFromOtherPlanLink farm={farm} />
            )}
        </div>
    );
};

export default connect((state: AppState) => ({
    farm: selectCurrentFarm(state),
    membershipFinishAt: state.membershipState.finishAt,
}))(TopNavUpgradePrompt);

const UpgradeFromOtherPlanLink = ({ farm }: { farm: Farm }) => {
    const { t } = useTranslation();
    return (
        <>
            <span>
                {t('current_plan')}: {farm.plan.displayName.toUpperCase()}
            </span>{' '}
            <UpgradeButton
                variant="yellow-outline"
                small
                onClick={() =>
                    Segment.track('Top nav change plan link clicked', { farmUuid: farm.uuid })
                }
                className="ml-4"
            >
                {t('change_plan')}
            </UpgradeButton>
        </>
    );
};

const UpgradeTrialLink = ({ farm, finishesAt }: { farm: Farm; finishesAt: Date }) => {
    const { t } = useTranslation();
    const { formatDistanceToNow } = useDateFormat();
    return (
        <div className="UpgradeTrialLink">
            <span className="pr-4">
                {t('trial_end', { sprintf: [formatDistanceToNow(finishesAt)] })}
            </span>
            <UpgradeButton
                variant="yellow"
                small
                onClick={() =>
                    Segment.track('Top nav upgrade trial link clicked', { farmUuid: farm.uuid })
                }
            />
        </div>
    );
};
