import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import type { FarmUser } from '@fieldmargin/webapp-farms';
import { Segment } from '@fieldmargin/webapp-reporting';
import type FullOperation from 'operations/FullOperation';
import type Operation from 'operations/Operation';
import type { AppState } from 'system/store';

import OperationDateAndTime from './OperationDateAndTime';

interface EditableOperationDatesProps {
    // From parent
    fullOperation: FullOperation;
    onSave: (fullOperation: FullOperation) => Promise<any>;

    // From redux
    farmUsers: FarmUser[];
}

const EditableOperationDates = ({
    fullOperation,
    farmUsers,
    onSave,
}: EditableOperationDatesProps) => {
    const handleSave = (newOperation: Operation) =>
        onSave(fullOperation.set('summary', newOperation));

    return (
        <>
            <CreatedDate
                operation={fullOperation.summary}
                farmUsers={farmUsers}
                onSave={handleSave}
            />
            <OperationDueDate operation={fullOperation.summary} onSave={handleSave} />
        </>
    );
};

export default connect((state: AppState) => ({
    farmUsers: state.farmUsersState.farmUsers,
}))(EditableOperationDates);

const OperationDueDate = ({
    operation,
    onSave,
}: {
    operation: Operation;
    onSave: (operation: Operation) => Promise<any>;
}) => {
    const { t } = useTranslation();
    if (operation.status === 'DONE') {
        return null;
    }

    const handleSave = (date: Date | null) =>
        onSave(operation.set('dueDate', date ? date : null)).then(() => {
            Segment.track('Field operation due date set', {
                farmUuid: operation.farmUuid,
                jobUuid: operation.uuid,
            });
        });

    return (
        <OperationDateAndTime
            label={t('create_task_due_date_title_set')}
            value={operation.dueDate ? operation.dueDate : null}
            onSave={handleSave}
            required={false}
        />
    );
};

const CreatedDate = ({
    operation,
    farmUsers,
    onSave,
}: {
    operation: Operation;
    farmUsers: FarmUser[];
    onSave: (note: Operation) => Promise<any>;
}) => {
    const { t } = useTranslation();
    const createdDate = operation.userCreatedDate
        ? operation.userCreatedDate
        : operation.createdDate;

    return (
        <OperationDateAndTime
            label={t('filters_activity_sort_created')}
            value={createdDate}
            onSave={(date: Date | null) =>
                onSave(operation.set('userCreatedDate', date ? date : null))
            }
            required={true}
            byUser={farmUsers.find((fu) => fu.id === operation.createdByUserId)}
        />
    );
};
