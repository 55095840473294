import { connect } from 'react-redux';
import { Segment } from '@fieldmargin/webapp-reporting';
import { useCurrentOperation } from 'hooks/useCurrentOperation';
import Comment from 'lib/model/Comment';
import { throttle } from 'lodash';
import type Media from 'media/Media';
import type Operation from 'operations/Operation';
import { saveOperationCommentApi, uploadOperationMediaApi } from 'operations/operations-api';
import { setOperationComment, setOperationMedia } from 'operations/operations-state';
import { bindActionCreators } from 'redux';
import ActivityLogCommentBox from 'sidebar/modules/activity-log/ActivityLogCommentBox';

interface OperationActivityLogCommentBoxProps {
    setOperationComment: ({
        operation,
        comment,
    }: {
        operation: Operation;
        comment: Comment;
    }) => void;
    setOperationMedia: ({ operation, newMedia }: { operation: Operation; newMedia: Media }) => void;
}

const OperationActivityLogCommentBox = ({
    setOperationComment,
    setOperationMedia,
}: OperationActivityLogCommentBoxProps) => {
    const { currentOperation } = useCurrentOperation();
    const operation = currentOperation?.summary;

    if (!operation) return null;

    const handleAddComment = async (comment: string) => {
        const promise = saveOperationCommentApi(
            operation,
            Comment({
                parentUuid: operation.uuid,
                comment,
                createdDate: new Date(),
            })
        );
        const savedComment = await promise;
        setOperationComment({ operation, comment: savedComment });
        Segment.track('Field operation comment added', {
            farmUuid: operation.farmUuid,
            jobUuid: operation.uuid,
        });
        return promise;
    };

    const handleFileUpload = async (file: any, progressCb: (progress: number) => void) =>
        uploadOperationMediaApi(
            operation,
            file,
            throttle((progressEvent) => {
                progressCb(Math.round((progressEvent.loaded * 100) / progressEvent.total));
            }, 500)
        ).then((newMedia) => {
            setOperationMedia({ operation, newMedia });
            Segment.track('Field operation photo added', {
                farmUuid: operation.farmUuid,
                jobUuid: operation.uuid,
            });
            return newMedia.fileName;
        });

    return <ActivityLogCommentBox addComment={handleAddComment} uploadFile={handleFileUpload} />;
};

export default connect(
    () => ({}),
    (dispatch) => bindActionCreators({ setOperationComment, setOperationMedia }, dispatch)
)(OperationActivityLogCommentBox);
