import type { Reducer } from 'react';
import { useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import type { Farm } from '@fieldmargin/webapp-farms';
import { useSegmentMountTracking } from '@fieldmargin/webapp-reporting';
import { usePromise } from '@fieldmargin/webapp-state';
import Button from '@fieldmargin/webapp-styling/components/button';
import classNames from 'classnames';
import { selectCurrentFarm } from 'farms/farms-state';
import {
    selectPesticideCheckFieldAndCurrentUsages,
    selectYearFieldUsagesFromFields,
} from 'fields/fields-selectors';
import { getFieldUsageCropCodes } from 'fields/FieldUsage';
import type { List } from 'immutable';
import { Set } from 'immutable';
import { selectPesticideInputUuids } from 'inputs/inputs-state';
import { getCropCheckData } from 'inputs/pesticides-api';
import { preventDefaultAnd } from 'lib/dom';
import { comparePropNameProp } from 'lib/util/text';
import Modal from 'modal/Modal';
import type FullOperation from 'operations/FullOperation';
import type Recording from 'operations/Recording';
import type {
    PesticideCheckResponse,
    PesticideCheckStatus,
} from 'operations/sidebar/pesticide-checking/PesticideCheck';
import { operationRecordingsToPesticideCheckRecordings } from 'operations/sidebar/pesticide-checking/PesticideCheck';
import PesticideCheckModal from 'operations/sidebar/pesticide-checking/PesticideCheckModal';
import PesticideCheckResultsModal from 'operations/sidebar/pesticide-checking/PesticideCheckResultsModal';
import PesticideCheckUpgradeModal from 'operations/sidebar/pesticide-checking/PesticideCheckUpgradeModal';
import type { AppState } from 'system/store';
import type { CodedItem, FieldAndCurrentUsage } from 'system/types';
import VerifiedShield from 'view/shield/VerifiedShield';
import { selectCurrentYear } from 'years/years-state';

import type { OperationRecording } from './operation-recording-utils';
import { getRecordingInfo } from './operation-recording-utils';
import type { PesticideCheckState, PesticideCheckStateAction } from './pesticide-check-state';
import {
    closePesticideCheck,
    pesticideCheckReducer,
    setCrop,
    setCropStage,
    setModal,
    setPest,
    setPesticideCheckResponse,
    setStatus,
} from './pesticide-check-state';

interface PesticidesCheckButtonProps {
    // From parent
    fullOperation?: FullOperation;
    className?: string;

    // From redux
    farm: Farm;
    year: number;
    pesticideInputUuids: List<string>;
    operationRecordings: List<OperationRecording>;
    cropCodes: string[];
    fields: FieldAndCurrentUsage[];
}

const PesticidesCheckButton = ({
    fullOperation,
    className,
    farm,
    year,
    operationRecordings,
    pesticideInputUuids,
    cropCodes,
    fields,
}: PesticidesCheckButtonProps) => {
    const { t } = useTranslation();

    const [{ modal, filters, pesticideCheckResponse }, dispatch] = useReducer<
        Reducer<PesticideCheckState, PesticideCheckStateAction>
    >(pesticideCheckReducer, {
        modal: undefined,
        filters: {},
        pesticideCheckResponse: undefined,
    });

    const { pending, setPromise } = usePromise<PesticideCheckResponse>((pestcideCheck) =>
        dispatch(setPesticideCheckResponse(pestcideCheck))
    );
    const fetchPesticideCheck = () =>
        setPromise(
            getCropCheckData(
                farm.uuid,
                year,
                operationRecordingsToPesticideCheckRecordings(
                    operationRecordings.toArray(),
                    fullOperation !== undefined ? fullOperation.summary.createdDate : new Date()
                ),
                fields.map((field) => ({
                    uuid: field.field.uuid,
                    cropPrincipleCode: field.currentUsage.cropCode,
                })),
                cropCodes,
                fullOperation !== undefined ? fullOperation.uuid : undefined
            )
        );

    const hasFields = fields.length > 0;
    const hasRecordings =
        operationRecordings.filter(
            (operationRecording) =>
                !operationRecording.recording.archived &&
                pesticideInputUuids.includes(operationRecording.input.uuid)
        ).size > 0;

    const isDisabled = !hasFields || !hasRecordings;

    const handleClick = preventDefaultAnd(() => {
        if (isDisabled) {
            dispatch(setModal('invalid'));
            return;
        }
        if (!farm.plan.cropCheck) {
            dispatch(setModal('locked'));
            return;
        }
        dispatch(setModal('check'));
    });

    const handleCheckModalDone = () => dispatch(setModal('results'));
    const hideModal = () => dispatch(closePesticideCheck());
    const setPestInState = (pest?: string) => dispatch(setPest(pest));
    const setCropStageInState = (cropStage?: string) => dispatch(setCropStage(cropStage));
    const setCropInState = (crop?: CodedItem) => dispatch(setCrop(crop));
    const setStatusInState = (status: PesticideCheckStatus[]) => dispatch(setStatus(status));

    return (
        <>
            {modal === 'invalid' && (
                <InvalidCheckModal
                    farm={farm}
                    hasFields={hasFields}
                    hasRecordings={hasRecordings}
                    onClose={hideModal}
                />
            )}
            {modal === 'locked' && <PesticideCheckUpgradeModal farm={farm} onClose={hideModal} />}
            {modal === 'check' && (
                <PesticideCheckModal
                    farm={farm}
                    onClose={hideModal}
                    onDone={handleCheckModalDone}
                    fetchPesticideCheck={fetchPesticideCheck}
                    pending={pending}
                    fieldUuids={Set(fields.map((field) => field.field.uuid))}
                    filterValues={filters}
                    filters={pesticideCheckResponse?.filters}
                    setPest={setPestInState}
                    setCropStage={setCropStageInState}
                    setCrop={setCropInState}
                />
            )}
            {modal === 'results' && pesticideCheckResponse !== undefined && (
                <PesticideCheckResultsModal
                    farm={farm}
                    onClose={hideModal}
                    operationRecordings={operationRecordings}
                    fields={fields}
                    pesticideCheckResponse={pesticideCheckResponse}
                    filterValues={filters}
                    setStatus={setStatusInState}
                    setPest={setPestInState}
                    setCropStage={setCropStageInState}
                    setCrop={setCropInState}
                />
            )}
            <Button
                onClick={handleClick}
                small
                passiveDisable={isDisabled}
                className={classNames('flex items-center gap-3', className)}
            >
                <VerifiedShield fill="#ffffff" />
                {t('check_pesticides')}
            </Button>
        </>
    );
};

export default connect(
    (
        state: AppState,
        {
            fieldUuids = Set(),
            recordings = Set(),
        }: { fieldUuids?: Set<string>; recordings?: Set<Recording> }
    ) => ({
        farm: selectCurrentFarm(state),
        year: selectCurrentYear(state),
        pesticideInputUuids: selectPesticideInputUuids(state),
        fields: selectPesticideCheckFieldAndCurrentUsages(state, fieldUuids),
        operationRecordings: getRecordingInfo(recordings, state.inputsState.inputs).sort(
            comparePropNameProp('input')
        ),
        cropCodes: getFieldUsageCropCodes(selectYearFieldUsagesFromFields(state, fieldUuids)),
    })
)(PesticidesCheckButton);

const InvalidCheckModal = ({
    farm,
    hasFields,
    hasRecordings,
    onClose,
}: {
    farm: Farm;
    hasFields: boolean;
    hasRecordings: boolean;
    onClose: VoidFunction;
}) => {
    const { t } = useTranslation();
    let content = t('pesticide_check_error_no_fields_or_inputs');
    if (!hasFields && hasRecordings) {
        content = t('pesticide_check_error_no_fields');
    } else if (hasFields && !hasRecordings) {
        content = t('pesticide_check_error_no_inputs');
    }
    useSegmentMountTracking('Pesticide check invalid modal seen', {
        farmUuid: farm.uuid,
        reason: content,
    });
    return (
        <Modal onClose={onClose}>
            <div className="bg-white rounded w-96 p-5 text-center">
                <p className="mb-4">{content}</p>
                <Button className="mx-auto" onClick={onClose}>
                    {t('ok')}
                </Button>
            </div>
        </Modal>
    );
};
